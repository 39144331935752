import { useState } from "react";

export const useTreeSearch = (initialNodes, searchProperty = "label") => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNodes, setFilteredNodes] = useState(initialNodes);

  const filterTree = (term = "", nodes) => {
    const search = (term || "").toString().toLowerCase(); // Ensure `term` is a string

    const filterNode = (node) => {
      // Check if the node or any child contains the search term
      if ((node[searchProperty] || "").toLowerCase().includes(search))
        return true;
      if (node.children) {
        return node.children.some(filterNode);
      }
      return false;
    };

    return nodes.reduce((acc, node) => {
      if (filterNode(node)) {
        acc.push({
          ...node,
          children: node.children ? filterTree(search, node.children) : [],
        });
      }
      return acc;
    }, []);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setFilteredNodes(filterTree(term, initialNodes));
  };

  return { searchTerm, setSearchTerm: handleSearch, filteredNodes };
};
