import React, { useEffect, useMemo, useState } from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
  closestCenter,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { useSelector } from "react-redux";
import BoardSection from "./BoardSection";
import TaskItem from "./TaskItem";

import {
  findBoardSectionContainer,
  getTaskById,
  initializeBoard,
} from "../../../../utilities/ObjectivesUtils";

const BoardSectionList = ({ filterInputValue, objectivesData }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  // const { objectivesData } = useSelector((state) => state.objectivesReducer);

  const convertedData = useMemo(() => {
    return objectivesData?.map((item) => ({
      ...item,
      title: item.name,
      // status: "not started",
    }));
  }, [objectivesData]);

  // Memoize the filtered tasks to avoid recomputation
  const filteredTasks = useMemo(() => {
    const lowerSearchText = filterInputValue.toLowerCase();
    return convertedData?.filter((item) => {
      const directMatch =
        item.name.toLowerCase().includes(lowerSearchText) ||
        item.description.toLowerCase().includes(lowerSearchText);

      const keyResultMatch = item.keyresults.some((kr) =>
        kr.name.toLowerCase().includes(lowerSearchText),
      );

      return directMatch || keyResultMatch;
    });
  }, [convertedData, filterInputValue]);

  const [boardSections, setBoardSections] = useState([]);

  useEffect(() => {
    if (filteredTasks) {
      setBoardSections(initializeBoard(filteredTasks));
    }
  }, [filteredTasks]);

  const [activeTaskId, setActiveTaskId] = useState(null);

  const handleDragStart = ({ active }) => setActiveTaskId(active.id);

  const handleDragOver = ({ active, over }) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    const overContainer = findBoardSectionContainer(boardSections, over?.id);

    if (!activeContainer || !overContainer || activeContainer === overContainer)
      return;

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      const activeIndex = activeItems.findIndex(
        (item) => item.id === active.id,
      );
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...boardSection,
        [activeContainer]: [
          ...boardSection[activeContainer].filter(
            (item) => item.id !== active.id,
          ),
        ],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(
            overIndex,
            boardSection[overContainer].length,
          ),
        ],
      };
    });
  };

  const handleDragEnd = ({ active, over }) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    const overContainer = findBoardSectionContainer(boardSections, over?.id);

    if (!activeContainer || !overContainer || activeContainer !== overContainer)
      return;

    const activeIndex = boardSections[activeContainer].findIndex(
      (task) => task.id === active.id,
    );
    const overIndex = boardSections[overContainer].findIndex(
      (task) => task.id === over?.id,
    );

    if (activeIndex !== overIndex) {
      setBoardSections((boardSection) => ({
        ...boardSection,
        [overContainer]: arrayMove(
          boardSection[overContainer],
          activeIndex,
          overIndex,
        ),
      }));
    }

    setActiveTaskId(null);
  };

  const dropAnimation = {
    ...defaultDropAnimation,
  };

  const task = activeTaskId ? getTaskById(filteredTasks, activeTaskId) : null;

  return (
    <div className="d-flex flex-row">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "32px",
          }}
        >
          {Object.keys(boardSections).map((boardSectionKey) => (
            <div key={boardSectionKey} style={{ display: "flex" }}>
              <BoardSection
                key={boardSectionKey}
                id={boardSectionKey}
                title={boardSectionKey}
                tasks={boardSections[boardSectionKey]}
              />
            </div>
          ))}
          <DragOverlay dropAnimation={dropAnimation}>
            {task ? <TaskItem task={task} /> : null}
          </DragOverlay>
        </div>
      </DndContext>
    </div>
  );
};
export default BoardSectionList;
