import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { secure_instance } from "../../../axios/axios-config";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import { ReactComponent as PerformanceIcon } from "../../../assets/icons/Views/Dashboard/Performance.svg";
import { ReactComponent as OkrsIcon } from "../../../assets/icons/Views/Dashboard/okrs.svg";
import { ReactComponent as SurveysIcon } from "../../../assets/icons/Views/Dashboard/surveys.svg";
import { DateRangePicker } from "../../../components/DatePickers/DateRangePicker";
import AnalyticsCardNew from "./AnalyticsCardNew";
import {
  GetGraphLoader,
  MyResponsiveLineLoader,
  PieChart,
} from "../../../utilities/Loaders";
import DashboardOverviewGraphs from "../../../components/Analytics/DashboardOverview";
import BarGraph from "../../../components/Analytics/BarGraph";
import { pieChartDummyData } from "../../../utilities/dummyGraphs/LineGraphData";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import ActiveTimePerTeam from "../../../components/Analytics/ActiveTimePerTeam";
import CommunicationTeam from "../../../components/Analytics/CommunicationTypeGraph";
import {
  setDashboardDateRange,
  triggerReFetch,
  updateDateFilter,
  updateOrganizationFilter,
} from "../../../redux/slices/dashboardSlice";
import { getRandomColor } from "../../../utilities/Utils";
import LineGraph from "../../../components/Analytics/LineGraph";

export default function Dashboard() {
  const dispatch = useDispatch();

  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );

  const [teamsData, setTeamsData] = useState([]); // teams to show inside dropdown
  const [cohortsData, setCohortsData] = useState([]); // cohorts to show inside dropdown
  const [usersData, setUsersData] = useState([]); // users to show inside dropdown

  const [globalFilterSearchValue, setGlobalFilterSearchValue] = useState("");
  const [isSearchMatch, setIsSearchMatch] = useState(true);
  const [globalSelectedFilter, setGlobalSelectedFilter] = useState("");
  const [globalSelectedCategory, setGlobalSelectedCategory] = useState("");
  // const [recentSearches, setRecentSearches] = useState([]);
  const [isRefreshAnalytics, setIsRefreshAnalytics] = useState(false);
  const [globalFilterChanged, setGlobalFilterChanged] = useState(false);
  const [globalFilteriDs, setGlobalFilteriDs] = useState([]);

  const [pieChartLegend, setPieChartLegend] = useState([]);
  const [organizationItemSelected, setOrganizationItemSelected] = useState({});
  const [organizationItemApplied, setOrganizationItemApplied] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [isTeamSelected, setIsTeamSelected] = useState(false);
  const [performanceOverviewGraphData, setPerformanceOverviewGraphData] =
    useState({});
  const [performanceOverviewGraphLoading, setPerformanceOverviewGraphLoading] =
    useState(false);

  const [totalSurveysOverviewGraphData, setTotalSurveysOverviewGraphData] =
    useState({});
  const [
    totalSurveysOverviewGraphLoading,
    setTotalSurveysOverviewGraphLoading,
  ] = useState(false);

  const [totalOKROverviewGraphData, setTotalOKROverviewGraphData] = useState(
    {},
  );
  const [totalOKROverviewGraphLoading, setTotalOKROverviewGraphLoading] =
    useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const nestedFilterAnchorEl = React.useRef(null);

  const fetchDataForGlobalFilteration = async () => {
    if (
      teamsData.length === 0 &&
      cohortsData.length === 0 &&
      usersData.length === 0
    ) {
      try {
        const teams = await secure_instance.request({
          url: `v1/teams/`,
          method: "GET",
        });
        const cohorts = await secure_instance.request({
          url: `v1/cohorts/`,
          method: "GET",
        });
        const userProfile = await secure_instance.request({
          url: `v1/user_profile/?type=all`,
          method: "GET",
        });
        const usersFormatConverted = userProfile.data?.map((obj) => {
          if (Object.prototype.hasOwnProperty.call(obj, "user_id")) {
            obj["name"] = `${obj.first_name} ${obj.last_name}`;
            return obj;
          }
        });

        setTeamsData(teams.data);
        setCohortsData(cohorts.data);
        setUsersData(usersFormatConverted);

        setOrganizationData([
          ...teams.data,
          ...cohorts.data,
          ...usersFormatConverted,
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  useEffect(() => {
    if (isTeamSelected) {
      setOrganizationData([...teamsData, ...cohortsData]);
    } else {
      setOrganizationData([...teamsData, ...usersData, ...cohortsData]);
    }
  }, [isTeamSelected]);

  useEffect(() => {
    const isTeamItemSelected = Object.prototype.hasOwnProperty.call(
      organizationItemApplied,
      "group_id",
    );
    if (isTeamItemSelected) {
      setIsTeamSelected(true);
    } else {
      setIsTeamSelected(false);
    }
  }, [organizationItemApplied]);

  const handleApplyOrganizationFilter = (item) => {
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }

    setOrganizationItemApplied(item === organizationItemSelected ? {} : item);
    handleNestedFilterationMenuClose();

    const userFilters = { ...organizationGlobalFilter };

    if (Object.keys(item).length === 0) {
      userFilters.filters = [];
    } else if (userFilters) {
      userFilters.filters = [
        {
          col: "group_id",
          operator: "==",
          value: item.group_id,
        },
      ];
    }

    dispatch(updateOrganizationFilter(userFilters));

    dispatch(triggerReFetch(true));
    setTimeout(() => {
      dispatch(triggerReFetch(false));
    }, 1000);
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);

    const dashboardGlobalFiltersClone = [...dashboardGlobalFilters];

    const updatedDateFilter = {
      col: "event_time",
      operator: "between",
      value: [
        dayjs(selectedStartDate).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(selectedEndDate).format("YYYY-MM-DD HH:mm:ss"),
      ],
    };

    dashboardGlobalFiltersClone[0] = updatedDateFilter;

    dispatch(
      setDashboardDateRange({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      }),
    );
    dispatch(triggerReFetch(true));
    setTimeout(() => {
      dispatch(triggerReFetch(false));
    }, 1000);
    dispatch(updateDateFilter(dashboardGlobalFiltersClone));
  };

  useEffect(() => {
    fetchDataForGlobalFilteration();

    const fetchPerformanceOverviewGraphData = async () => {
      setPerformanceOverviewGraphLoading(true);

      const performanceOverviewData = await secure_instance.request({
        url: `v1/graph/performance`,
        method: "GET",
      });
      const currentCount =
        performanceOverviewData.data.comparisondata.current_month[0].count;
      const previousCount =
        performanceOverviewData.data.comparisondata.previous_month[0].count;

      let percentageChange;

      if (previousCount === 0) {
        percentageChange = currentCount > 0 ? 100 : 0;
      } else {
        percentageChange =
          ((currentCount - previousCount) / previousCount) * 100;
      }
      setPerformanceOverviewGraphData({
        graphData: performanceOverviewData.data,
        percentageChange,
      });
      setPerformanceOverviewGraphLoading(false);
    };
    fetchPerformanceOverviewGraphData();

    const fetchTotalSurveysOverviewGraphData = async () => {
      setTotalSurveysOverviewGraphLoading(true);
      const totalSurveysOverviewData = await secure_instance.request({
        url: `v1/graph/dashboard/survey`,
        method: "GET",
      });

      setTotalSurveysOverviewGraphData(totalSurveysOverviewData.data);
      setTotalSurveysOverviewGraphLoading(false);
    };
    fetchTotalSurveysOverviewGraphData();

    const fetchTotalOKROverviewGraphData = async () => {
      setTotalOKROverviewGraphLoading(true);
      const totalOKROverviewData = await secure_instance.request({
        url: `v1/graph/dashboard/objectives`,
        method: "GET",
      });

      setTotalOKROverviewGraphData(totalOKROverviewData.data);
      setTotalOKROverviewGraphLoading(false);
    };
    fetchTotalOKROverviewGraphData();
  }, []);

  // console.log("totalSurveysOverviewGraphData", totalSurveysOverviewGraphData);
  const totalAnswered =
    totalSurveysOverviewGraphData?.answered?.[0]?.count ?? 0;
  const totalUnanswered =
    totalSurveysOverviewGraphData?.unanswered?.[0]?.count ?? 0;

  const chartColor = totalSurveysOverviewGraphData?.category_data?.map(() =>
    getRandomColor(),
  );

  // const diffInDays = dayjs(endDate).diff(dayjs(startDate), "day");
  // console.log(
  //   "organizationItemSelected in DASHBOARD",
  //   organizationItemSelected,
  // );
  return (
    <>
      <div>
        <div
          className="page-body-new d-flex justify-content-between"
          style={{ padding: "0 24px 44px 27px" }}
        >
          <div className="col-12" style={{ width: "100%" }}>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: "32px" }}
            >
              <PopperDropdown
                anchorRef={nestedFilterAnchorEl}
                isDropdownOpen={isNestedFilterationMenuOpen}
                handleToggle={() => handleNestedFilterationMenuOpen()}
                handleClose={() => handleCancelOrganizationFilter()}
                dropdownDisplayText="Users"
                customRenderFieldChildren={
                  <div
                    className="d-flex align-items-center head4-semi-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Organization{" "}
                    <CaretDown
                      stroke="black"
                      stroke-width="3"
                      className="ml-2"
                    />
                  </div>
                }
                dropdownWithSearchAndCheckbox
                dropdownData={organizationData}
                // handleClickCheckbox={handleClickCheckbox}
                handleApply={handleApplyOrganizationFilter}
                handleCancel={handleCancelOrganizationFilter}
                dropdownItemsSelected={organizationItemSelected}
                keyToAccess="name"
                // handleOptionSelected={handleGroupBySelected}
                // activeMenuItem={"Users"}
              />

              <div
                className="d-flex align-items-center"
                style={{
                  height: "36px",
                  background: "#F8F9FA",
                  border: "1px solid #E9ECEF",
                  borderRadius: "10px",
                  padding: "6px",
                  cursor: "pointer",
                }}
              >
                <DateRangePicker
                  value={{ from: startDate, to: endDate }}
                  fetchDatesData={fetchDatesData}
                  setIsDateMenuOpen={setIsDateMenuOpen}
                  isDateMenuOpen={isDateMenuOpen}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
            </div>

            <div className="row p-0">
              <div className="col-lg-4">
                <DashboardOverviewGraphs
                  icon={<PerformanceIcon />}
                  graphType={"Performance"}
                  quantifyText={`${performanceOverviewGraphData?.percentageChange}% Increase`}
                  time={`Last 30 Days`}
                  bottomText={`Compared to month of ${dayjs().subtract(1, "month").format("MMMM")}`}
                  isLoading={performanceOverviewGraphLoading}
                  loaderComponent={
                    <GetGraphLoader
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      isOverviewGraph={true}
                    />
                  }
                  graph={
                    <BarGraph
                      groupByGraphKeys={["y"]}
                      graphData={
                        performanceOverviewGraphData.graphData?.graphdata ?? []
                      }
                      isInteractive={false}
                      currentFilters={[]}
                      graphName={"Performance"}
                      indexBy={"x"}
                      hideDateOnToolTip={true}
                      tooltip={null}
                      padding={0.2}
                      colors={"#A790FF"}
                      isAxisBottomDisplay={false}
                      isAxisLeftDisplay={false}
                      enableGridX={false}
                      enableGridY={false}
                      borderRadius={5}
                      margin={{
                        top: 0,
                        // dynamic spacing to keep bars tight
                        right:
                          20 -
                          performanceOverviewGraphData?.graphData?.graphdata
                            ?.length,
                        bottom: 0,
                        left:
                          20 -
                          performanceOverviewGraphData?.graphData?.graphdata
                            ?.length,
                      }}
                      graphContainerHeight={"106px"}
                    />
                  }
                />
              </div>

              <div className="col-lg-4 ">
                <DashboardOverviewGraphs
                  icon={<OkrsIcon />}
                  graphType={`Total OKR’s: ${totalOKROverviewGraphData?.total_objectives?.[0]?.count ?? ""}`}
                  quantifyText={`Total key results Acheived: ${totalOKROverviewGraphData?.KRs_achieved?.[0]?.count ?? ""}`}
                  time={`Last 30 Days`}
                  bottomText={`OKR’s In progress: ${totalOKROverviewGraphData?.in_progress?.[0]?.count ?? ""}`}
                  isLoading={totalOKROverviewGraphLoading}
                  loaderComponent={
                    <MyResponsiveLineLoader
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      isOverviewGraph={true}
                    />
                  }
                  graph={
                    <LineGraph
                      margin={{ top: 5, right: 30, bottom: 5, left: 30 }}
                      graphContainerHeight={"106px"}
                      isInteractive={false}
                      axisLeft={false}
                      axisBottom={false}
                      graphData={
                        totalOKROverviewGraphData?.graph_data?.length > 0
                          ? [
                              {
                                id: "Series 1",
                                data: totalOKROverviewGraphData?.graph_data.map(
                                  (data) => {
                                    return {
                                      x: data.date,
                                      y: data.count,
                                    };
                                  },
                                ),
                              },
                            ]
                          : []
                      }
                      yScale={{
                        type: "linear",
                      }}
                    />
                  }
                />
              </div>

              <div className="col-lg-4 ">
                <DashboardOverviewGraphs
                  icon={<SurveysIcon />}
                  graphType={`Total Surveys: ${!totalSurveysOverviewGraphLoading ? totalAnswered + totalUnanswered : ""}`}
                  quantifyText={`Answered:${totalAnswered}    Unanswered:${totalUnanswered} `}
                  time={`Last 30 Days`}
                  bottomText={totalSurveysOverviewGraphData?.category_data?.map(
                    (data) => data?.category,
                  )}
                  colors={chartColor}
                  pieChart
                  loaderComponent={
                    <PieChart
                      data={pieChartDummyData}
                      setPieChartLegend={setPieChartLegend}
                      customColors={{ scheme: "greys" }}
                      isInteractive={false}
                      enableArcLabels={false}
                    />
                  }
                  isLoading={totalSurveysOverviewGraphLoading}
                  graph={
                    <PieChart
                      data={
                        totalSurveysOverviewGraphData?.category_data?.map(
                          (item) => ({
                            id: item.category,
                            label: item.category,
                            value: item.count,
                          }),
                        ) || []
                      }
                      colors={chartColor}
                      isInteractive={false}
                      setPieChartLegend={setPieChartLegend}
                    />
                  }
                />
              </div>
            </div>
            <div className="row p-0">
              <div className="d-flex flex-column col-lg-12">
                <AnalyticsCardNew
                  graph={"Activity"}
                  graphName={"Activity"}
                  teamsData={teamsData}
                  usersData={usersData}
                  cohortsData={cohortsData}
                  globalFilterSearchValue={globalFilterSearchValue}
                  isSearchMatch={isSearchMatch}
                  setIsSearchMatch={setIsSearchMatch}
                  globalSelectedCategory={globalSelectedCategory}
                  globalSelectedFilter={globalSelectedFilter}
                  globalFilteriDs={globalFilteriDs}
                  isRefreshAnalytics={isRefreshAnalytics}
                  setIsRefreshAnalytics={setIsRefreshAnalytics}
                  globalFilterChanged={globalFilterChanged}
                  setGlobalFilterChanged={setGlobalFilterChanged}
                  indexBy={"user_id"}
                  graphType="activityGraph"
                  productivityGraph
                  isTeamSelected={isTeamSelected}
                  userProfilesExtracted={
                    organizationItemApplied?.team_group &&
                    organizationItemApplied?.team_group[0]?.group_members.map(
                      (group) => group.user_profile,
                    )
                  }
                  organizationItemSelected={organizationItemSelected}
                />
              </div>
            </div>

            <div className="row p-0">
              <div className="col-lg-6">
                <ActiveTimePerTeam />
              </div>
              <div className="col-lg-6">
                <CommunicationTeam />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
