// import { ResponsiveBarCanvas } from "@nivo/bar";
import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { getBarGraphValues } from "../../utilities/GraphUtils";
// import dayjs from "dayjs";
import { ResponsiveLine } from "@nivo/line";
import { LineGraphEmptyStateIcon } from "../../utilities/GraphUtils";

// import { addMissingDates } from "../../utilities/dateConversion";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered.

const LineGraph = (props) => {
  const {
    graphData,
    teamDataForMapping,
    axisBottom,
    margin,
    graphContainerHeight,
    xScale,
    xFormat,
    yScale,
    communicationGraph,
    colors,
    isInteractive,
    axisLeft,
  } = props;

  const mapIdToTeam = (id) => {
    const element = teamDataForMapping.find((element) => {
      console.log("element.id ===> ", element);
      console.log("id ===>", id);
      // if (element.data !== undefined) {
      // }
      console.log({ id });
      console.log({ teamDataForMapping });
      return parseInt(element.id) === parseInt(id);
    });
    return element?.name;
  };
  const isGraphDataEmpty =
    graphData.length === 0 ||
    graphData.every(
      (item) => Array.isArray(item?.data) && item?.data?.length === 0,
    );
  return (
    <div
      style={{
        height: graphContainerHeight ? graphContainerHeight : "290px",
        width: "100%",
      }}
    >
      {isGraphDataEmpty && <LineGraphEmptyStateIcon />}
      <ResponsiveLine
        animate
        isInteractive={isInteractive ?? true}
        axisBottom={axisBottom}
        // axisBottom={{
        //   format: "%Hh%M",
        //   legend: "time scale",
        //   legendOffset: -12,
        //   tickValues: "every 10 hours",
        //   tickRotation: 47,
        // }}
        enableArea={true}
        enableGridX={false}
        enableGridY={false}
        axisLeft={axisLeft}
        // animate={false}
        areaOpacity={0.2}
        areaBlendMode="normal"
        lineWidth={2}
        enablePoints={false}
        // axisLeft={{
        //   legend: "linear scale",
        //   legendOffset: 12,
        // }}
        // axisLeft={null}
        curve="monotoneX"
        data={graphData}
        enablePointLabel
        colors={colors ? colors : { scheme: "paired" }}
        // height={400}
        // pointLabelYOffset={-12}
        // margin={{
        //   bottom: 60,
        //   left: 80,
        //   right: 20,
        //   top: 20,
        // }}
        margin={margin}
        pointBorderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        pointBorderWidth={1}
        pointSize={16}
        // fill={false}
        // pointSymbol={function noRefCheck() {}}
        // sliceTooltip={}
        tooltip={({ point, id, value, color, indexValue }) => (
          <div
            className="d-flex"
            // style={{ background: "white", padding: "10px" }}
            style={{
              background: "white",
              color: "inherit",
              fontSize: "inherit",
              borderRadius: "2px",
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
              padding: "5px 9px",
            }}
          >
            {console.log("point", point)}
            {console.log("valuevalue", value)}
            {/* EXPAND THIS FUNCTION FURTHER TO INCLUDE ALL CONDITIONS INSTEAD OF DOING HERE */}
            <div className="d-flex align-items-center flex-column justify-content-center mr-2">
              <div
                style={{
                  backgroundColor: point.color,
                  width: "16px",
                  height: "16px",
                }}
              />
            </div>
            <div>
              {communicationGraph ? (
                <div>
                  {"Communication Type:"}
                  <span style={{ fontWeight: "bold" }}> {point.serieId}</span>
                </div>
              ) : (
                <div>
                  {"Team:"}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {/* ------------- The teams name was not mapping because of this property name change from backend -------------*/}
                    {/* {mapIdToTeam(point.data.group_id)} */}
                    {mapIdToTeam(point.data.group_id)}
                  </span>
                </div>
              )}
              <div>
                {"Date:"}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {point.data.xFormatted}
                </span>
              </div>
              <div>
                {"Event Count:"}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {point.data.yFormatted}
                </span>
              </div>
            </div>
            {/* {graphName}: {getToolTipNames(id)} - {value} */}
          </div>
        )}
        useMesh
        // width={900}
        // xFormat={"time:%Y-%m-%d %H:%M:%S"}
        xFormat={xFormat}
        // xScale={{
        //   format: "%Y-%m-%dT%H:%M:%S",
        //   precision: "hour",
        //   type: "time",
        //   useUTC: false,
        // }}
        xScale={xScale}
        // yScale={{
        //   type: "linear",
        // }}
        yScale={yScale}
      />
    </div>
  );
};

export default LineGraph;
