/* eslint-disable indent */
import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { lineGraphData } from "./dummyGraphs/LineGraphData";
import { PieGraphEmptyStateIcon } from "./GraphUtils";

export const GetGraphLoader = ({ margin, isOverviewGraph }) => {
  const dummyLoaderGraphData = [
    {
      time: "Jan",
      dummy: 41,
    },
    {
      time: "Feb",
      dummy: 165,
    },
    {
      time: "Mar",
      dummy: 27,
    },
    {
      time: "Apr",
      dummy: 55,
    },
    {
      time: "May",
      dummy: 195,
    },
    {
      time: "Jun",
      dummy: 185,
    },
    {
      time: "Jul",
      dummy: 126,
    },
    {
      time: "Aug",
      dummy: 126,
    },
    {
      time: "Sep",
      dummy: 126,
    },
    {
      time: "Oct",
      dummy: 126,
    },
    {
      time: "Nov",
      dummy: 126,
    },
    {
      time: "Dec",
      dummy: 126,
    },
  ];

  const commonBarProps = {
    keys: ["dummy"],
    indexBy: "time",
    margin: margin || { top: 5, right: 80, bottom: 110, left: 60 },
    valueScale: { type: "linear" },
    indexScale: { type: "band", round: true },
    colors: "#DEE2E6",
    enableLabel: false,
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    axisLeft: null,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    enableGridY: false,
    isInteractive: false,
  };

  return (
    <>
      {isOverviewGraph ? (
        <div style={{ height: isOverviewGraph ? "100px" : "400px" }}>
          <ResponsiveBar
            {...commonBarProps}
            padding={0.2}
            borderRadius={5}
            data={dummyLoaderGraphData.slice(0, 6)}
          />
        </div>
      ) : (
        <ResponsiveBar
          {...commonBarProps}
          data={dummyLoaderGraphData}
          padding={0.6}
          borderRadius={15}
        />
      )}
    </>
  );
};

export const MyResponsiveLineLoader = ({ margin, isOverviewGraph }) => {
  return (
    <div style={{ height: isOverviewGraph ? "100px" : "266px", width: "100%" }}>
      <ResponsiveLine
        data={lineGraphData}
        margin={margin ? margin : { top: 0, right: 0, bottom: 30, left: 0 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        colors={"#DEE2E6"}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        lineWidth={0}
        enablePoints={false}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.6}
        useMesh={true}
        curve="natural"
        enableGridX={false}
        enableGridY={false}
        isInteractive={false}
      />
    </div>
  );
};

export const PieChart = ({
  data,
  customColors,
  isInteractive,
  enableArcLabels,
  colors,
}) => {
  return (
    <div style={{ height: "106px", width: "100%" }}>
      {data.length === 0 && <PieGraphEmptyStateIcon />}
      <ResponsivePie
        data={data}
        // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        colors={colors}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        isInteractive={isInteractive ?? true}
        {...(customColors ? { colors: customColors } : {})} // Optional colors prop
        arcLinkLabelsSkipAngle={10}
        enableArcLinkLabels={false}
        enableArcLabels={enableArcLabels ?? true}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
    </div>
  );
};
