import React, { memo, useMemo, useState } from "react";
import BarGraph from "../../../components/Analytics/BarGraph";
import { secure_instance } from "../../../axios/axios-config";
import { getGraphGranularityMappings } from "../../../utilities/Utils";
import { getBarGraphValues } from "../../../utilities/GraphUtils";

const SurveyResponseRate = ({ graphName, graphType }) => {
  const [graphData, setGraphData] = useState([]);

  const getGraphAPIData = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/survey_response/graph/",
        method: "GET",
      });
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  useMemo(async () => {
    const data = await getGraphAPIData();
    setGraphData(data?.length > 0 ? data : []);
    console.log("data => ", data);
  }, []);

  // useMemo(async () => {
  //   if (isTriggerProductivityGraphRefetch || isTriggerRefetch) {
  //     const query = generateGraphQuery(
  //       dashboardGlobalFilters,
  //       graphUserFilters,
  //       selectedGroupByItem,
  //       getGraphGranularityMappings(selectedGranularity),
  //     );

  //     // handleGraphDataFetch(query, selectedGroupByItem, selectedGranularity);
  //   }
  // }, [isTriggerProductivityGraphRefetch, isTriggerRefetch]);

  return (
    <div className="w-100" style={{ padding: "27px 0" }}>
      <div
        style={{
          height: "398px",
          border: "1px solid #C6C6C6",
          borderRadius: "8px",
          background: "#FFFFFF",
          boxShadow: "2px 2px 15px 0px #E5D5F2",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "24px 32px 0 32px",
            marginBottom: graphData.length === 0 && "110px",
          }}
        >
          <div className="s1 d-flex align-items-center">{graphName}</div>
        </div>

        <div
          style={{
            minWidth: "95%",
            maxWidth: "99%",
            paddingLeft: "30px",
            marginTop: "10px",
          }}
        >
          <BarGraph
            groupByGraphKeys={["count"]}
            graphData={graphData}
            selectedGranularity={"monthly"}
            // currentFilters={[]}
            graphName={graphName}
            indexBy={"month"}
            hideDateOnToolTip={true}
            tooltip={null}
            padding={0.95}
            colors={"#9300FF"}
            // isAxisBottomDisplay={true}
            // isAxisLeftDisplay={true}
            // enableGridX={false}
            // enableGridY={true}
            borderRadius={5}
            margin={{ top: 0, right: 0, bottom: 30, left: 30 }}
            graphContainerHeight={"320px"}
            useCanvas={true}
            isAxisBottomDisplay={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              // legend: "DumpTableMain.eventTime",
              legendPosition: "middle",
              legendOffset: 65,
              // ------------------- THIS FUNCTION CUSOTMIZES FONT, BUT DOESNT WORK WITH CANVAS VERSION  -------------------
              // tickTextStyle: {
              //   fontSize: 24, // Increase this value to make the font larger
              // },
              format: (value) => {
                return getBarGraphValues(value, "month", "month").slice(0, 3);
              },
              // format: (value) => {
              //   return getEmojisMapping(value);
              // },
            }}
          />
          {/* <BarGraph
            // startDate={startDate}
            // endDate={endDate}
            // dateChanged={dateChanged}
            // setDateChanged={setDateChanged}
            selectedGranularity={"monthly"}
            // groupByGraphKeys={groupByGraphKeys}
            // usersData={usersData}
            graphContainerHeight={"380px"}
            // selectedGroupByItem={selectedGroupByItem}
            graphData={graphData}
            // graphData={ffff}
            // currentFilters={[]}
            // memberSelected={menuItemSelected}
            // globalSelectedCategory={globalSelectedCategory}
            // isRefreshAnalytics={isRefreshAnalytics}
            // globalSelectedFilter={globalSelectedFilter}
            graphName={graphName}
            indexBy={"month"}
            // groupByGraphKeys={groupByGraphKeys}
            // tooltip={null}
            // tooltip={(input) => {
            //   return input;
            // }}
            // hideDateOnToolTip={hideDateOnToolTip}
            // Top10PerformersGraph={Top10PerformersGraph}
            // matchingUsersInGraphData={matchingUsersInGraphData}

            padding={0.09}
            // colors={"#A790FF"}
            // isAxisBottomDisplay={false}
            // isAxisLeftDisplay={false}
            // enableGridX={false}
            // enableGridY={false}
            borderRadius={10}
            // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default memo(SurveyResponseRate);
