import React, { useEffect, useState } from "react";
import AnalyticsCardNew from "../../../views/pages/Dashboard/AnalyticsCardNew";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { secure_instance } from "../../../axios/axios-config";
import CumulativeDashboardGraph from "../../Analytics/CumulativeDashboardGraph";
import ActiveTimePerTeam from "../../Analytics/ActiveTimePerTeam";
import CommunicationTeam from "../../Analytics/CommunicationTypeGraph";

const AnalyticsSideBar = () => {
  const dispatch = useDispatch();

  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );

  // const [isMenu, setIsMenu] = useState(false);

  // const [actionMenu, setActionMenu] = useState(false);
  const [teamsData, setTeamsData] = useState([]); // users to show inside dropdown
  const [cohortsData, setCohortsData] = useState([]); // users to show inside dropdown
  const [usersData, setUsersData] = useState([]); // users to show inside dropdown
  // const [globalFilterationData, setGlobalFilterationData] = useState([]); // users to show inside dropdown

  const [globalFilterSearchValue, setGlobalFilterSearchValue] = useState("");
  const [isSearchMatch, setIsSearchMatch] = useState(true);
  const [globalSelectedFilter, setGlobalSelectedFilter] = useState(""); // users which have been added from dropdown
  const [globalSelectedCategory, setGlobalSelectedCategory] = useState(""); // users which have been added from dropdown
  // const [recentSearches, setRecentSearches] = useState([]); // users which have been added from dropdown
  const [isRefreshAnalytics, setIsRefreshAnalytics] = useState(false); // users which have been added from dropdown
  const [globalFilterChanged, setGlobalFilterChanged] = useState(false);
  const [globalFilteriDs, setGlobalFilteriDs] = useState([]);
  // const [productivityGraphData, setProductivityGraphData] = useState([]);
  const [pieChartLegend, setPieChartLegend] = useState([]);
  const [organizationItemSelected, setOrganizationItemSelected] = useState({});
  const [organizationItemApplied, setOrganizationItemApplied] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [isTeamSelected, setIsTeamSelected] = useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [nestedFilterAnchorEl, setNestedFilterAnchorEl] = useState(null);
  // const isNestedFilterationMenuOpen = Boolean(nestedFilterAnchorEl);

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const nestedFilterAnchorEl = React.useRef(null);

  // const handleToggleCumulativeGraphDropDown = () => {
  //   setCumulativeGraphDropDownOpen((prevOpen) => !prevOpen);
  // };
  // const handleCloseCumulativeGraphDropDown = () => {
  //   setCumulativeGraphDropDownOpen(false);
  // };

  // const handleGlobalFilterSelection = (selectedFilter, category) => {
  // 	setGlobalSelectedFilter(selectedFilter);
  // 	setGlobalSelectedCategory(category);
  // 	// console.count("GLOBAL FILTER CALLLED");

  // 	if (category === "cohorts") {
  // 		console.log("cohortsssssssssssssssssss");
  // 	}

  // 	setGlobalFilterChanged(true);

  // 	const isAlreadySearchExists = recentSearches.includes(selectedFilter.name);
  // 	if (!isAlreadySearchExists) {
  // 		setRecentSearches([...recentSearches, selectedFilter.name]);
  // 	}
  // 	if (globalFilterChanged) {
  // 		setGlobalFilterChanged(false);
  // 	}
  // };

  const fetchDataForGlobalFilteration = async () => {
    if (
      teamsData.length === 0 &&
      cohortsData.length === 0 &&
      usersData.length === 0
    ) {
      try {
        const teams = await secure_instance.request({
          url: `v1/teams/`,
          method: "GET",
        });
        const cohorts = await secure_instance.request({
          url: `v1/cohorts/`,
          method: "GET",
        });
        const userProfile = await secure_instance.request({
          url: `v1/user_profile/?type=all`,
          method: "GET",
        });
        const usersFormatConverted = userProfile.data?.map((obj) => {
          if (Object.prototype.hasOwnProperty.call(obj, "user_id")) {
            obj["name"] = `${obj.first_name} ${obj.last_name}`;
            return obj;
          }
        });

        setTeamsData(teams.data);
        setCohortsData(cohorts.data);
        setUsersData(usersFormatConverted);

        setOrganizationData([
          ...teams.data,
          ...cohorts.data,
          ...usersFormatConverted,
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // const handleIndividualFilterationMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleIndividualFilterationMenuClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleNestedFilterationMenuOpen = (event) => {
  //   setIsNestedFilterationMenuOpen(event.currentTarget);
  // };

  // const handleNestedFilterationMenuClose = () => {
  //   setIsNestedFilterationMenuOpen(null);
  // };

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  // const handleClickMenuItem = (item, uniqueID, category) => {
  //   const selectedItems = [...menuItemSelected];
  //   const categoryIndex = selectedItems.findIndex(
  //     (selectedItem) => selectedItem.category === category,
  //   );

  //   if (categoryIndex === -1) {
  //     // Category doesn't exist, create a new object with the category and item
  //     selectedItems.push({ category, items: [item] });
  //   } else {
  //     // Category already exists, check if item is already selected
  //     const selectedItemIndex = selectedItems[categoryIndex].items.findIndex(
  //       (el) => el[uniqueID] === item[uniqueID],
  //     );
  //     if (selectedItemIndex === -1) {
  //       // Item is not selected, add it to the items array
  //       selectedItems[categoryIndex].items.push(item);
  //     } else {
  //       selectedItems[categoryIndex].items.splice(selectedItemIndex, 1);
  //     }
  //   }

  //   setMenuItemSelected(selectedItems);
  // };
  // const isSelected = (name) => organizationItemSelected?.indexOf(name) !== -1;

  const handleClickCheckbox = (item) => {
    // const selectedIndex = organizationItemSelected.findIndex((x) => x === item);

    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(organizationItemSelected, item);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(organizationItemSelected.slice(1));
    // } else if (selectedIndex === organizationItemSelected.length - 1) {
    //   newSelected = newSelected.concat(organizationItemSelected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     organizationItemSelected.slice(0, selectedIndex),
    //     organizationItemSelected.slice(selectedIndex + 1),
    //   );
    // }
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  useEffect(() => {
    if (isTeamSelected) {
      setOrganizationData([...teamsData, ...cohortsData]);
    } else {
      setOrganizationData([...teamsData, ...usersData, ...cohortsData]);
    }
  }, [isTeamSelected]);
  useEffect(() => {
    const isTeamItemSelected = Object.prototype.hasOwnProperty.call(
      organizationItemApplied,
      "group_id",
    );
    if (isTeamItemSelected) {
      setIsTeamSelected(true);
    } else {
      setIsTeamSelected(false);
    }
  }, [organizationItemApplied]);

  // const handleApplyOrganizationFilter = () => {
  //   setOrganizationItemApplied(organizationItemSelected);
  //   handleNestedFilterationMenuClose();

  //   const organizationGlobalFilterClone = organizationGlobalFilter;

  //   const userFilters = organizationGlobalFilterClone;

  //   if (Object.keys(organizationItemSelected).length === 0) {
  //     userFilters.filters = [];
  //   } else if (userFilters) {
  //     userFilters.filters = [
  //       {
  //         col: "group_id",
  //         operator: "==",
  //         value: organizationItemSelected.group_id,
  //       },
  //     ];
  //   }

  // const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
  //   setStartDate(selectedStartDate);
  //   setEndDate(selectedEndDate);

  //   const dashboardGlobalFiltersClone = dashboardGlobalFilters;

  //   const updatedDateFilter = {
  //     col: "event_time",
  //     operator: "between",
  //     value: [
  //       dayjs(selectedStartDate).format("YYYY-MM-DD HH:mm:ss"),
  //       dayjs(selectedEndDate).format("YYYY-MM-DD HH:mm:ss"),
  //     ],
  //   };

  //   dashboardGlobalFiltersClone[0] = updatedDateFilter;

  // };

  useEffect(() => {
    fetchDataForGlobalFilteration();
  }, []);

  return (
    // <div>AnalyticsSideBar</div>
    <>
      <div className="d-flex flex-column">
        <AnalyticsCardNew
          graph={"Productivity"}
          graphName={"Productivity"}
          teamsData={teamsData}
          usersData={usersData}
          cohortsData={cohortsData}
          // graphDataTemp={productivityGraphData}
          globalFilterSearchValue={globalFilterSearchValue}
          isSearchMatch={isSearchMatch}
          setIsSearchMatch={setIsSearchMatch}
          globalSelectedCategory={globalSelectedCategory}
          globalSelectedFilter={globalSelectedFilter}
          globalFilteriDs={globalFilteriDs}
          isRefreshAnalytics={isRefreshAnalytics}
          setIsRefreshAnalytics={setIsRefreshAnalytics}
          globalFilterChanged={globalFilterChanged}
          setGlobalFilterChanged={setGlobalFilterChanged}
          indexBy={"user_id"}
          graphType="productivityGraph"
          productivityGraph
          isTeamSelected={isTeamSelected}
          userProfilesExtracted={
            // Object.keys(organizationItemApplied).length > 0 &&
            organizationItemApplied?.team_group &&
            organizationItemApplied?.team_group[0]?.group_members.map(
              (group) => group.user_profile,
            )
          }
          organizationItemSelected={organizationItemSelected}
          // usersData={}
          // graphQuery={productivityGraphQuery}
        />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <ActiveTimePerTeam />
        </div>
        <div className="col-lg-6">
          <CommunicationTeam />
        </div>
      </div>
    </>
  );
};

export default AnalyticsSideBar;
