import React, { useEffect, useState } from "react";
import { ReactComponent as List } from "../../../assets/icons/List.svg";
import { ReactComponent as Group } from "../../../assets/icons/SquaresFour.svg";
import ConnectionCard from "../../../components/Cards/ConnectionCards";
import { secure_instance } from "../../../axios/axios-config";
import { useDispatch, useSelector } from "react-redux";
import Integrate from "../../../components/SideMenus/Integrate";
import BackgroundBlur from "../../../components/sub-components/Blur";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import Sync from "../../../components/SideMenus/Sync";
import CreateTeam from "../../../components/SideMenus/CreateTeam";
import Modal from "@mui/material/Modal";
import Button from "../../../components/sub-components/Button";
import { Table } from "../../../components/Tables/Table";
import { ReactComponent as Google } from "../../../assets/icons/Google.svg";
import { ReactComponent as Microsoft } from "../../../assets/icons/Microsoft.svg";
import { ReactComponent as Disconnected } from "../../../assets/icons/Disconnected.svg";
import { ReactComponent as Connected } from "../../../assets/icons/Connected.svg";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import Field from "../../../components/sub-components/Field";
import {
  handleCancelSetup,
  handleConnectionStatus,
  handleIndividualTeamGroupData,
  handleTeamsPopup,
  toggleSyncMenu,
} from "../../../redux/slices/identityProviderSlice";
import CustomBox from "../../../components/Modals/CustomBox";

export default function IdentityProvidersList() {
  const dispatch = useDispatch();
  const ProviderConnectionInfo = useSelector(
    (state) => state.identityProviderReducer.ProviderConnectionInfo,
  );
  const { IdentityProvders } = useSelector(
    (state) => state.identityProviderReducer.ProviderConnectionInfo,
  );
  const { providersName } = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.IdentityProvders,
  );
  const isTeamsModal = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.isTeamsModal,
  );
  const [view, setView] = useState("group");
  const [newProvidersData, setNewProvidersData] = useState([]);
  const [isSyncMenu, setIsSyncMenu] = useState(true);
  const [sideMenu, setSideMenu] = useState(false);
  const [sideMenuData, setSideMenuData] = useState("");
  const [cancelSyncSetup, setCancelSyncSetup] = useState(false);
  const [showSyncMenu, setShowSyncMenu] = useState(false);
  const mediaQuery = useWindowDimensions();
  const [isAnyConnected, setIsAnyConnected] = useState(false);
  const [page, setPage] = useState(1);
  const [filterInputValue, setFilterInputValue] = useState("");
  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const [userSelected, setUserSelected] = useState("none");
  const [userApplied, setUserApplied] = useState("");
  const nestedFilterAnchorEl = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (showSyncMenu === true) {
      document.body.style.overflow = "hidden";
    } else if (showSyncMenu === false) {
      document.body.style.overflow = "unset";
    }
  }, [showSyncMenu]);

  useEffect(() => {
    setNewProvidersData(providersData);
    getConnectionStatus();
  }, []);

  useEffect(() => {
    if (IdentityProvders.connected && isSyncMenu) {
      dispatch(toggleSyncMenu());
      setShowSyncMenu(true);
    }
  }, [IdentityProvders]);

  useEffect(() => {
    if (cancelSyncSetup) {
      dispatch(toggleSyncMenu());
      dispatch(handleCancelSetup());
      setCancelSyncSetup(false);
    }
  }, [cancelSyncSetup]);

  useEffect(() => {
    const newData = editProviders();
    setNewProvidersData(newData);
  }, [ProviderConnectionInfo]);

  useEffect(() => {
    if (IdentityProvders.connected) {
      setIsAnyConnected(true);
    }
  }, [IdentityProvders]);

  const getConnectionStatus = async () => {
    dispatch(handleConnectionStatus());
  };

  const handleSideMenu = (data) => {
    document.body.style.overflow = "hidden";
    setSideMenuData(data);
    setSideMenu(true);
  };

  const handleSideMenuClose = () => {
    document.body.style.overflow = "unset";
    setSideMenu(false);
  };

  const tableColumns = [
    {
      key: "tool",
      name: "Tools",
      padding: "0 0 0 32px",
      accessor: (row) => (
        <>
          {/* ASK THE BACKEND PEEPS TO SEND IMAGE_URL OF THESE FROM BACKEND */}
          {row.tool === "GSuite" ? (
            <Google
              style={{ width: "31px", height: "32px", marginRight: "15px" }}
            />
          ) : (
            row.tool === "Microsoft Azure" && (
              <Microsoft
                style={{ width: "31px", height: "32px", marginRight: "15px" }}
              />
            )
          )}
          <span
            className={`b1 ${
              row.tool !== IdentityProvders.providersName && isAnyConnected
                ? "grey6"
                : "grey8"
            }`}
          >
            {row.tool}
          </span>
        </>
      ),
      minWidth: 300,
    },

    {
      key: "category",
      name: "Type",
      accessor: (row) => (
        <span
          className={`b1 ${
            row.tool !== IdentityProvders.providersName && isAnyConnected
              ? "grey6"
              : "grey8"
          }`}
        >
          {row.category}
        </span>
      ),
      minWidth: 300,
    },
    {
      key: "status",
      name: "Category",
      accessor: (row) => (
        <span className="b1 grey8">
          {row.status.toLowerCase() === "connected" ? (
            <div>
              <Connected style={{ marginRight: "10px" }} />
              <span className="b1 grey8">Connected</span>
            </div>
          ) : (
            <div>
              <Disconnected style={{ marginRight: "10px" }} />
              <span
                className={`b1 ${
                  row.tool !== IdentityProvders.providersName && isAnyConnected
                    ? "grey6"
                    : "grey8"
                }`}
              >
                Not Connected
              </span>
            </div>
          )}
        </span>
      ),
      minWidth: 300,
    },
    {
      key: "action",
      name: "Status",
      accessor: (row) => (
        <span
          className={`b1 ${
            row.tool !== IdentityProvders.providersName && isAnyConnected
              ? "grey6 disableEvents"
              : "primary-color"
          }`}
          // onClick={() =>
          //   row.status.toLowerCase() === "connected"
          //     ? setShowSyncMenu(true)
          //     : handleSideMenu(row)
          // }
        >
          {row.status.toLowerCase() === "connected" ? "Sync" : "Connect"}
        </span>
      ),
      minWidth: 120,
    },
  ];

  const providersData = [
    {
      id: 1,
      tool: "GSuite",
      category: "Design",
      status: "Not Connected",
    },
    {
      id: 2,
      tool: "Microsoft Azure",
      category: "Planning",
      status: "Not Connected",
    },
  ];

  const editProviders = () => {
    return providersData.map((item) => {
      var temp = { ...item };
      if (temp.tool === providersName) {
        temp.status = "Connected";
      } else {
        temp.disablePointer = true; // Add disablePointer if status is not connected
      }
      return temp;
    });
  };

  const handleConnect = async (data) => {
    try {
      const response = await secure_instance.request({
        url: data.tool === "GSuite" ? "v1/gauth/" : "v1/mauth/",
        method: "Get",
      });
      window.location =
        data.tool === "GSuite"
          ? response.data.redirect_url
          : response.data.result;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  const handleTeamsPopupToggle = () => {
    dispatch(handleTeamsPopup());
  };

  const getTeamsPopup = (data) => {
    const newData = {
      tenant_id: data.tenant_id,
      name: data.name,
      group_id: data.id,
    };
    dispatch(handleIndividualTeamGroupData(newData));
    dispatch(handleTeamsPopup());
  };

  const getCancelSetup = () => {
    handleOpen();
  };

  const handleCancelSetupConfirm = () => {
    setCancelSyncSetup(true);
    setIsSyncMenu(false);
    setShowSyncMenu(false);
    handleClose();
  };

  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };

  const handleApplyUserType = (obj) => {
    if (obj === userApplied) {
      setUserSelected("");
      setUserApplied("");
    } else {
      setUserSelected(obj);
      setUserApplied(obj);
    }
    handleNestedFilterationMenuClose();
  };

  const handleCancelUserType = () => {
    handleNestedFilterationMenuClose();
    setUserSelected(userApplied);
  };
  const handleClickCheckbox = async (item) => {
    if (item === userSelected) {
      setUserSelected("");
    } else {
      setUserSelected(item);
    }
  };

  const getActiveView = (activeView) =>
    view === activeView ? "#7B2CBF" : "#6C757D";

  return (
    <div>
      <>
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            style={{ marginLeft: "50px", marginRight: "50px" }}
            aria-describedby="modal-modal-description"
          >
            <CustomBox>
              <div className="s1 grey7">
                Are you sure you want to cancel the setup process?
              </div>

              <Button
                buttonHollowLg
                title="No, Go Back"
                style={{ width: "213px", margin: "15px 0" }}
                onClick={() => handleClose()}
              />
              <Button
                buttonFilledMd
                title="Yes, Cancel"
                style={{ color: "white", width: "213px" }}
                onClick={() => handleCancelSetupConfirm()}
              />
            </CustomBox>
          </Modal>
          <Integrate
            data={sideMenuData}
            handleConnect={handleConnect}
            handleSideMenuClose={handleSideMenuClose}
            sideMenu={sideMenu}
          />
          {sideMenu && <BackgroundBlur closeMenu={handleSideMenuClose} />}
          {/* WHEN USER CLICKS ON TEAMS SHOW THIS POPUP */}
          {isTeamsModal && (
            <>
              <CreateTeam handleTeamsPopupToggle={handleTeamsPopupToggle} />
            </>
          )}
        </>
        <div
          className="page-body-new"
          style={{ display: "block", padding: "0 44px" }}
        >
          <div
            className="d-flex flex-row justify-content-end align-items-center"
            style={{ marginBottom: "50px" }}
          >
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className="d-flex head4-medium grey8">
                  Connect your Preference
                </span>
                <span className="d-flex body1 grey6 mt-1">
                  You can only connect one service at a time.
                </span>
              </div>
              {mediaQuery.width > 768 && (
                <div className="d-flex align-items-between">
                  <>
                    <List
                      fill="none"
                      stroke={getActiveView("list")}
                      onClick={() => setView("list")}
                      style={{
                        marginRight: "12px",
                        cursor: "pointer",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    <Group
                      fill="none"
                      stroke={getActiveView("group")}
                      style={{
                        cursor: "pointer",
                        width: "40px",
                        height: "40px",
                      }}
                      onClick={() => setView("group")}
                    />
                  </>
                </div>
              )}
            </div>
          </div>
          <Sync
            IdentityProvders={IdentityProvders}
            showSyncMenu={showSyncMenu}
            syncMenuShow={ProviderConnectionInfo}
            handleTeamsPopup={getTeamsPopup}
            handleCancelSetup={getCancelSetup}
          />
          {showSyncMenu && <BackgroundBlur closeMenu={getCancelSetup} />}
          {/* ---------------------------------- This div should be a component */}
          <div
            style={{
              border: view === "list" ? "1px solid #F5F5F5" : "none",
              paddingLeft: view === "list" && "27px",
              paddingRight: view === "list" && "27px",
              borderRadius: "10px",
              boxShadow:
                view === "list"
                  ? "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
                  : "none",
            }}
            // d-flex flex-column
            className="w-100"
          >
            <div
              className="d-flex align-items-center"
              style={{
                padding: "20px 0",
                paddingTop: view === "list" ? "20px" : "0",
              }}
            >
              <PopperDropdown
                anchorRef={nestedFilterAnchorEl}
                isDropdownOpen={isNestedFilterationMenuOpen}
                handleToggle={() => handleNestedFilterationMenuOpen()}
                handleClose={() => handleNestedFilterationMenuClose()}
                dropdownDisplayText="Users"
                customRenderFieldChildren={
                  <div
                    className="d-flex align-items-center body1"
                    style={{
                      cursor: "pointer",
                      border: "1px solid #9300FF",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "10px",
                      marginRight: "16px",
                    }}
                  >
                    {userApplied === "" ? "Type" : userApplied}{" "}
                    <CaretDown
                      stroke="black"
                      stroke-width="2"
                      className="ml-2"
                    />
                  </div>
                }
                dropdownWithSearchAndCheckbox
                dropdownData={[
                  ...new Set(newProvidersData.map((data) => data.category)),
                ]}
                handleClickCheckbox={handleClickCheckbox}
                handleApply={handleApplyUserType}
                handleCancel={handleCancelUserType}
                dropdownItemsSelected={userSelected}
                keyToAccess={"none"}
              />

              <Field
                inputFieldFullSearchIcon
                placeholder={`Search`}
                value={filterInputValue}
                onChange={(e) => setFilterInputValue(e.target.value)}
                style={{
                  width: "27%",
                  // minWidth: "100%",
                  height: "40px",
                  paddingLeft: "52px",
                  borderRight: "1px solid #e9ecef",
                  borderRadius: "10px",
                  background: "white",
                }}
                containerStyle={{ flex: 1 }}
              />
            </div>

            {mediaQuery.width > 768 && view === "list" ? (
              <>
                <Table
                  headerBackgroundColor="#F7FBFF"
                  columns={tableColumns}
                  data={
                    userApplied !== ""
                      ? newProvidersData.filter(
                          (data) => data.category === userApplied,
                        )
                      : newProvidersData
                  }
                  handleTableRowClick={(row) => {
                    row.tool !== IdentityProvders.providersName &&
                    isAnyConnected
                      ? ""
                      : row.status.toLowerCase() === "connected"
                        ? setShowSyncMenu(true)
                        : handleSideMenu(row);
                  }}
                  inputValue={filterInputValue}
                  page={page}
                  setTablePage={(page) => setPage(page)}
                  columnIndexToRenderIcon={2}
                  displayIcon
                  // disablePointer={IdentityProvders.providersName}
                />
              </>
            ) : (
              <div>
                <div className="connection-card-container d-flex flex-row">
                  <ConnectionCard
                    identityProviders
                    setShowSyncMenu={setShowSyncMenu}
                    handleConnect={handleConnect}
                    handleSideMenu={handleSideMenu}
                    cardData={
                      userApplied !== ""
                        ? newProvidersData.filter(
                            (data) => data.category === userApplied,
                          )
                        : newProvidersData
                    }
                    columns={tableColumns}
                    inputValue={filterInputValue}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
