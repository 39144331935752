import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { Slider } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Field from "../../../../components/sub-components/Field";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/Plus.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/icons/Minus.svg";
import CustomSelect from "../../../../components/sub-components/Select";
import { getObjectivesDrawerTableData } from "../../../../redux/slices/ObjectivesSlice";
import TreeViewSelect from "../../../../components/sub-components/TreeViewDropdown";
import { useTreeSearch } from "../../../../utilities/Hooks";

const thresholdTypes = {
  0: "percentage",
  1: "money",
};

const KeyResultsForm = ({
  showConfirmationModal,
  getInputFieldsStyles,
  getInputFieldHeadingTextColor,
  keyResultsList,
  setKeyResultsList,
  keyResultsTableType,
  objectivesDropdownData,
  currentProgressDisabledStates,
}) => {
  const dispatch = useDispatch();

  const mainNodes = [
    {
      value: "organization",
      label: "Organization",
      children: [
        {
          value: "department",
          label: "Department",
          children: objectivesDropdownData?.objectives_departments?.map(
            (item) => ({
              value: "department" + item.id,
              label: item.name,
            }),
          ),
        },
        {
          value: "teams",
          label: "Teams",
          children: objectivesDropdownData?.objectives_teams?.map((item) => {
            return {
              value: "team" + item.id, // stringify to avoid duplicate
              label: item.name,
            };
          }),
        },
        {
          value: "users",
          label: "Users",
          children: objectivesDropdownData?.objectives_user_profile?.map(
            (user) => {
              return {
                value: "user" + user.user_id, // stringify to avoid duplicate
                label: user.first_name + " " + user.last_name,
              };
            },
          ),
        },
      ],
    },
  ];

  const {
    searchTerm,
    setSearchTerm: handleMainSearch,
    filteredNodes,
  } = useTreeSearch(mainNodes);

  const handleAddClick = () => {
    if (Array.isArray(keyResultsList)) {
      setKeyResultsList([
        ...keyResultsList,
        {
          name: "",
          threshold_type: "",
          threshold_value: "",
          KeyResultFor: [],
        },
      ]);
    }
  };

  const handleRemoveKeyResult = (index) => {
    const newList = keyResultsList.filter((_, i) => i !== index);
    setKeyResultsList(newList);
  };

  const handleChange = (key, value, index) => {
    const newValue = value;

    // Apply numeric validation only if the key is 'threshold_type'
    if (key === "threshold_value" && !/^\d*$/.test(value)) {
      return; // Prevent the update if the value is not numeric
    }

    // Proceed with normal update for other fields or valid 'threshold_type'
    const newList = keyResultsList.map((item, i) => {
      if (i === index) {
        return { ...item, [key]: newValue };
      }
      return item;
    });
    setKeyResultsList(newList);
  };
  useEffect(() => {
    if (keyResultsTableType && keyResultsTableType?.name) {
      const fetchData = async () => {
        try {
          const result = await dispatch(
            getObjectivesDrawerTableData(keyResultsTableType?.name),
          ).unwrap();
        } catch (err) {
          console.log("error ", err);
        }
      };

      fetchData();
    }
  }, [keyResultsTableType]);

  return (
    <>
      {keyResultsList.length > 0
        ? keyResultsList.map((x, i) => (
            <div key={`key-result-${i}`}>
              <div className="mt-5">
                <div
                  className={`body1-medium mb-2 ${getInputFieldHeadingTextColor()}`}
                >
                  <span>
                    {i + 1}
                    {". "}
                  </span>
                  Key Result
                </div>
                <Field
                  style={getInputFieldsStyles()}
                  name="name"
                  value={x.name}
                  onChange={(e) => handleChange("name", e.target.value, i)}
                  disabled={
                    showConfirmationModal || currentProgressDisabledStates
                  }
                  inputFieldFull
                />
              </div>

              <div className="row">
                {!currentProgressDisabledStates && (
                  <div className="col-6 mt-1 pr-3 pt-3 pl-0 mb-4">
                    <span
                      className={`body1-medium ${getInputFieldHeadingTextColor()}`}
                    >
                      Measure as
                    </span>
                    <div className="d-flex mt-3">
                      <CustomSelect
                        items={[
                          { value: "percentage", label: "Percentage" },
                          { value: "money", label: "Money" },
                        ]}
                        onChange={(e) =>
                          handleChange("threshold_type", e.target.value, i)
                        }
                        value={x?.threshold_type}
                        menuMaxHeight="400px"
                        disabled={currentProgressDisabledStates}
                      />
                    </div>
                  </div>
                )}

                <div className="col-6 p-3 mt-1">
                  <div
                    className={`body1-medium mb-3 ${getInputFieldHeadingTextColor()}`}
                  >
                    Success Threshold
                  </div>
                  {x?.threshold_type == "percentage" ? (
                    <Slider
                      defaultValue={50}
                      aria-label="Default"
                      value={x.threshold_value}
                      onChange={(e) =>
                        handleChange("threshold_value", e.target.value, i)
                      }
                      disabled={currentProgressDisabledStates}
                      valueLabelFormat={(value) => `${value}`}
                      valueLabelDisplay="on"
                    />
                  ) : (
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          height: "52px",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      style={{
                        backgroundColor: currentProgressDisabledStates
                          ? "#F8F9FA"
                          : "#FFFFFF",
                      }}
                      value={x.threshold_value}
                      onChange={(e) =>
                        handleChange("threshold_value", e.target.value, i)
                      }
                      disabled={currentProgressDisabledStates}
                    />
                  )}
                </div>
                {currentProgressDisabledStates && (
                  <div className="col-6 mt-1 pr-3 pt-3 pl-0 mb-4">
                    <div
                      className={`body1-medium mb-3 ${getInputFieldHeadingTextColor()}`}
                    >
                      Current Threshold
                    </div>
                    {x?.threshold_type?.label == "Percentage" ||
                    x?.threshold_type?.label == "percentage" ||
                    x?.threshold_type == "Percentage" ||
                    x?.threshold_type == "percentage" ? (
                      <Slider
                        defaultValue={0}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        value={x.current_value}
                        onChange={(e) =>
                          handleChange("current_value", e.target.value, i)
                        }
                      />
                    ) : (
                      <TextField
                        id="outlined-start-adornment"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                            height: "52px",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={x.current_value}
                        onChange={(e) =>
                          handleChange("current_value", e.target.value, i)
                        }
                      />
                    )}
                  </div>
                )}
              </div>

              <div>
                <span
                  className={`body1-medium ${getInputFieldHeadingTextColor()}`}
                >
                  Who is this Key Result for
                </span>
                <TreeViewSelect
                  nodes={filteredNodes}
                  appliedItems={x.KeyResultFor}
                  setAppliedItems={(selectedItems) =>
                    handleChange("KeyResultFor", selectedItems, i)
                  }
                  objectivesChecked={x?.checked ?? ""}
                  disabled={currentProgressDisabledStates}
                  searchTerm={searchTerm}
                  handleSearch={handleMainSearch}
                  readOnly={false}
                />
              </div>

              {!currentProgressDisabledStates && (
                <div className="d-flex justify-content-end mt-5">
                  {keyResultsList.length - 1 === i && (
                    <div
                      className="mr-5 mb-4"
                      onClick={handleAddClick}
                      style={{ cursor: "pointer" }}
                    >
                      <PlusIcon
                        fill={"#7B2CBF"}
                        stroke={"#7B2CBF"}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                      <span className="s1" style={{ color: "#7B2CBF" }}>
                        Add another Key Result
                      </span>
                    </div>
                  )}
                  {keyResultsList.length > 0 && (
                    <div
                      onClick={() => handleRemoveKeyResult(i)}
                      style={{ cursor: "pointer" }}
                    >
                      <MinusIcon
                        fill={"#7B2CBF"}
                        stroke={"#7B2CBF"}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                      <span className="s1" style={{ color: "#7B2CBF" }}>
                        Remove this Key Result
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        : !currentProgressDisabledStates && (
            <div className="d-flex align-items-center justify-content-end mt-5">
              <div onClick={handleAddClick} style={{ cursor: "pointer" }}>
                <PlusIcon
                  fill={"#7B2CBF"}
                  stroke={"#7B2CBF"}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
                <span className="s1" style={{ color: "#7B2CBF" }}>
                  Add a Key Result
                </span>
              </div>
            </div>
          )}
    </>
  );
};

export default KeyResultsForm;
