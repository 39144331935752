import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardGraph from "./DashboardGraph";
import {
  // generateQueryForLineGraph,
  generateQueryForSynchrounousCommunicationGraph,
} from "../../utilities/CubeUtils";
import { getGraphGranularityMappings } from "../../utilities/Utils";
import { secure_instance } from "../../axios/axios-config";

const CommunicationTeam = () => {
  // const dispatch = useDispatch();
  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );
  const user = useSelector((state) => state.authReducer.user);

  const [selectedGranularity, setSelectedGranularity] = useState("weekly");
  const [selectedGroupByOption, setSelectedGroupByOption] = useState("teams");

  const cumulativeGraphAnchorRef = useRef(null);
  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);

  const groupByAnchorEl = useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = useState(false);
  const [teamDataForMapping, setTeamDataForMapping] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const generateQuery = (selectedGranularity, groupByOption) => {
    const query = generateQueryForSynchrounousCommunicationGraph(
      dashboardGlobalFilters,
      organizationGlobalFilter,
      getGraphGranularityMappings(selectedGranularity),
      groupByOption,
    );
    return query;
  };

  const getGraphAPIData = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/graph/line",
        method: "Post",
        data: query,
      });
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  // ------------  SHOULD BE HITTING TWO DIFFERENT API'S WITH FILTERS OF SYNC AND ASYNC ------------

  const getGraphData = async (query) => {
    setIsGraphLoading(true);
    const response = await getGraphAPIData(query);

    if (!response?.graph) return [];

    const mergedData = response.graph[0].data?.reduce(
      (acc, obj) => {
        // Determine the target group based on event_name
        const targetGroup =
          obj.event_name === "communication_call_misc" ? acc.sync : acc.async;

        // Accumulate `y` values by date
        targetGroup[obj.x] = (targetGroup[obj.x] || 0) + obj.y;

        return acc;
      },
      { sync: {}, async: {} },
    );

    const transformedData = [
      {
        id: "sync",
        data: Object.entries(mergedData.sync).map(([x, y]) => ({ x, y })),
      },
      {
        id: "async",
        data: Object.entries(mergedData.async).map(([x, y]) => ({ x, y })),
      },
    ];

    setIsGraphLoading(false);

    return transformedData;
  };

  const fetchGraphData = async () => {
    const query = generateQuery(selectedGranularity, selectedGroupByOption);
    const graphData = await getGraphData(query);
    setGraphData(graphData ?? []);
  };

  const handleGranularityOptionSelected = async (e) => {
    setSelectedGranularity(e.target.id);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(e.target.id, selectedGroupByOption);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  const handleGroupByOptionSelected = async (e) => {
    setSelectedGroupByOption(e.target.id);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(selectedGranularity, e.target.id);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  const handleOptionSelected = async (e) => {
    setSelectedGranularity(e.target.id);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(e.target.id, selectedGroupByOption);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  // Fetch data when component first loads
  useEffect(() => {
    fetchGraphData();
  }, []);

  // Fetch data on trigger refetch
  useEffect(() => {
    if (isTriggerRefetch) {
      fetchGraphData();
    }
  }, [isTriggerRefetch, selectedGranularity]);

  return (
    <DashboardGraph
      title={"Synchrounous Vs Asynchronous Communication"}
      isGraphLoading={isGraphLoading}
      graphData={graphData}
      setGraphData={setGraphData}
      isTriggerRefetch={isTriggerRefetch}
      dashboardGlobalFilters={dashboardGlobalFilters}
      getGraphData={getGraphData}
      generateQuery={generateQuery}
      selectedGranularity={selectedGranularity}
      setSelectedGranularity={setSelectedGranularity}
      teamDataForMapping={teamDataForMapping}
      cumulativeGraphAnchorRef={cumulativeGraphAnchorRef}
      handleGenericMenuOpen={handleGenericMenuOpen}
      handleGenericMenuClose={handleGenericMenuClose}
      handleGranularityOptionSelected={handleGranularityOptionSelected}
      handleGroupByOptionSelected={handleGroupByOptionSelected}
      handleOptionSelected={handleOptionSelected}
      groupByAnchorEl={groupByAnchorEl}
      isGroupByMenuOpen={isGroupByMenuOpen}
      setIsGroupByMenuOpen={setIsGroupByMenuOpen}
      isCumulativeGraphDropDownOpen={isCumulativeGraphDropDownOpen}
      setCumulativeGraphDropDownOpen={setCumulativeGraphDropDownOpen}
      groupByMenuItems={["Users", "Teams"]}
      selectedGroupByOption={selectedGroupByOption}
      communicationGraph
    />
  );
};

export default CommunicationTeam;
