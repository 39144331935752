// import React from "react";

import React, { useMemo, useState } from "react";
import {
  capitalizeFirstAndRemoveLastTwoLetters,
  detectDateFormat,
  getFormatForCurrentGranularity,
  getGraphGranularityMappings,
  getTickValueIntervals,
} from "../../utilities/Utils";
import PopperDropdown from "../DropDowns/PopperDropdown";
import { MyResponsiveLineLoader } from "../../utilities/Loaders";
import FullPageLoading from "../Loading/FullPageLoading";
import LineGraphHourTest from "./LineGraph";
import LineGraph from "./LineGraph";
import CustomSelect from "../sub-components/Select";
const DashboardGraph = ({
  title,
  isGraphLoading,
  graphData,
  setGraphData,
  isTriggerRefetch,
  dashboardGlobalFilters,
  getGraphData,
  generateQuery,
  selectedGranularity,
  setSelectedGranularity,
  teamDataForMapping,
  cumulativeGraphAnchorRef,
  handleGenericMenuOpen,
  handleGenericMenuClose,
  handleGranularityOptionSelected,
  handleGroupByOptionSelected,
  groupByAnchorEl,
  isGroupByMenuOpen,
  setIsGroupByMenuOpen,
  isCumulativeGraphDropDownOpen,
  setCumulativeGraphDropDownOpen,
  communicationGraph,
  generateQueryProps,
  ActiveTimePerTeam,
  selectedGroupByOption,
  groupByMenuItems,
}) => {
  const detectedFormat = detectDateFormat(graphData);

  const isGraphDataEmpty =
    graphData.length === 0 ||
    graphData.every(
      (item) => Array.isArray(item.data) && item.data.length === 0,
    );

  return (
    <div className="key-result-cards-container" style={{ height: "400px" }}>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: isGraphDataEmpty && !isGraphLoading && "110px" }}
      >
        <div className="s1 d-flex align-items-center">{title}</div>
        <div className="d-flex align-items-center">
          {/* <PopperDropdown
            anchorRef={groupByAnchorEl}
            isDropdownOpen={isGroupByMenuOpen}
            handleToggle={() => handleGenericMenuOpen(setIsGroupByMenuOpen)}
            handleClose={() => handleGenericMenuClose(setIsGroupByMenuOpen)}
            dropdownDisplayText={"Group By"}
            menuItems={groupByMenuItems}
            handleOptionSelected={handleGroupByOptionSelected}
            activeMenuItem={selectedGroupByOption}
          /> */}
          <CustomSelect
            items={groupByMenuItems.map((item) => ({
              value: item.toLowerCase(),
              label: item,
            }))}
            value={selectedGroupByOption}
            onChange={(e) => handleGroupByOptionSelected(e.target.value)}
            useCustomMenu
          />
          <div className="mr-3" />

          {/* <PopperDropdown
            anchorRef={cumulativeGraphAnchorRef}
            isDropdownOpen={isCumulativeGraphDropDownOpen}
            handleToggle={() =>
              handleGenericMenuOpen(setCumulativeGraphDropDownOpen)
            }
            handleClose={() =>
              handleGenericMenuClose(setCumulativeGraphDropDownOpen)
            }
            dropdownDisplayText={capitalizeFirstAndRemoveLastTwoLetters(
              selectedGranularity === "daily" ? "Dayly" : selectedGranularity,
            )}
            menuItems={["Hourly", "Daily", "Weekly", "Monthly", "Yearly"]}
            handleOptionSelected={handleGranularityOptionSelected}
            activeMenuItem={selectedGranularity}
          /> */}
          <CustomSelect
            items={["Hourly", "Daily", "Weekly", "Monthly", "Yearly"].map(
              (item) => ({
                value: item.toLowerCase(),
                label: item,
              }),
            )}
            value={selectedGranularity}
            onChange={(e) => handleGranularityOptionSelected(e.target.value)}
            useCustomMenu
          />
        </div>
      </div>
      <div style={{ marginTop: "34px" }}>
        {isGraphLoading ? (
          <div
            style={{
              minWidth: "95%",
              paddingLeft: "30px",
              maxHeight: "fit-content",
              // height: "400px",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{
                position: "relative",
                margin: "0 auto",
                width: "100%",
                height: "100%",
              }}
            >
              <MyResponsiveLineLoader />
              <div style={{ position: "absolute", left: "39%", top: "35%" }}>
                <FullPageLoading height="50%" />
                <div className="h5 grey9 mt-3">Loading Data</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <LineGraph
              margin={{ top: 0, right: 30, bottom: 50, left: 10 }}
              graphData={graphData}
              communicationGraph={communicationGraph}
              teamDataForMapping={teamDataForMapping}
              xFormat={
                selectedGranularity !== "hourly"
                  ? "time:%Y-%m-%d"
                  : detectedFormat === "YYYY-MM-DD"
                    ? "time:%Y-%m-%d"
                    : "time:%Y-%m-%d %H:%M:%S"
              }
              xScale={{
                format:
                  detectedFormat !== null && detectedFormat === "YYYY-MM-DD"
                    ? "%Y-%m-%d"
                    : "%Y-%m-%dT%H:%M:%S",
                precision:
                  selectedGranularity === "weekly"
                    ? "day"
                    : getGraphGranularityMappings(selectedGranularity),
                type: "time",
                useUTC: false,
              }}
              yScale={{
                type: "linear",
              }}
              axisBottom={
                isGraphDataEmpty
                  ? null
                  : {
                      format: getFormatForCurrentGranularity(
                        getGraphGranularityMappings(selectedGranularity),
                      ),
                      legendOffset: -12,
                      tickRotation: 47,
                      tickValues: getTickValueIntervals(
                        getGraphGranularityMappings(selectedGranularity),
                      ),
                    }
              }
              axisLeft={null}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardGraph;
