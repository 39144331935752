import React, { useState } from "react";
import eye from "../../assets/icons/Eye.svg";
import note from "../../assets/icons/NotePencil.svg";
// import caret_down from "../../assets/icons/CaretDown.svg";
import { ReactComponent as Search } from "../../assets/icons/MagnifyingGlass.svg";
import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";
import Checkbox from "@mui/material/Checkbox";
import BpCheckbox from "./Checkbox";

export default function Field(props) {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  if (props.inputField) {
    return (
      <div>
        <input
          type="text"
          className="input-field"
          placeholder={props.placeholder || null}
          style={{ ...props.style }}
        />
      </div>
    );
  } else if (props.inputFieldFull) {
    return (
      <div>
        <input
          required={props.required}
          autoComplete={(props.autoComplete && "off") || ""}
          type="text"
          className={`${props.className} input-field-full s1`}
          name={props.name}
          value={props.value}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder || null}
          style={{ ...props.style }}
          onChange={props.onChange}
          disabled={props.disabled}
          onBlur={props.onBlur}
        />
      </div>
    );
  } else if (props.inputFieldFullNumber) {
    return (
      <div>
        <input
          required={props.required}
          autoComplete={(props.autoComplete && "off") || ""}
          type="number"
          maxLength="3"
          className={`${props.className} no-spinner input-field-full s1`} // no-spinner to remove arrows
          name={props.name}
          value={props.value}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder || null}
          style={{ ...props.style }}
          onChange={props.onChange}
          disabled={props.disabled}
          onBlur={props.onBlur}
        />
      </div>
    );
  } else if (props.inputFieldFullReadOnly) {
    return (
      <div
        style={{ position: "relative", ...props.style }}
        className="d-flex flex-row flex-wrap align-items-center"
        onClick={handleShow}
      >
        <div className="add-members-wrapper">
          <input
            required={props.required}
            autoComplete={(props.autoComplete && "off") || ""}
            type="text"
            className={`${props.className} input-field-full s1`}
            name={props.name}
            value={props.value}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder || null}
            style={{ ...props.style }}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onClick={props.onClick}
            disabled
          />
          {/* <div className="add-members-search-icon">
						<img src={caret_down} style={{ cursor: "pointer" }} />
					</div>
			 */}

          {!props.noDropDown && (
            <div className="add-members-search-icon">
              <CaretDown stroke="#343A40" style={{ cursor: "pointer" }} />
            </div>
          )}
        </div>
      </div>
    );
  } else if (props.inputFieldFullIcon) {
    return (
      <div className="d-flex flex-row align-items-center">
        <div className="search-icon">
          <Search
            stroke="#343A40"
            stroke-width="3"
            style={{ cursor: "pointer" }}
          />
        </div>
        <input
          type="text"
          className="input-field-full-icon s2"
          placeholder={props.placeholder || null}
          style={{ ...props.style }}
          disabled={props.disabled || false}
          readOnly={props.readOnly || false}
        />
      </div>
    );
  } else if (props.inputFieldFullSearchIcon) {
    return (
      <div
        className="d-flex flex-row align-items-center"
        style={{ ...props.containerStyle, position: "relative" }}
      >
        <div className="search-icon" onClick={props.toggleIcon}>
          <Search
            stroke="#343A40"
            stroke-width="3"
            style={{ cursor: "pointer" }}
          />
        </div>
        <input
          type="text"
          className="input-field-full-icon body2-medium grey6"
          placeholder={props.placeholder || null}
          style={{ ...props.style }}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    );
  } else if (props.passwordField) {
    return (
      <div className="d-flex flex-row">
        <input
          type="password"
          className="input-field-icon"
          placeholder={props.placeholder || "Password"}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
        <div className="icon">
          <img src={eye} style={{ cursor: "pointer" }} />
        </div>
      </div>
    );
  } else if (props.passwordFieldFull) {
    return (
      <div
        className="d-flex flex-row align-items-center"
        style={{ ...props.style }}
      >
        <input
          type={props.showPassword ? "text" : "password"}
          className={`${props.className} border-right-0 input-field-full-icon`}
          placeholder={props.placeholder || null}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
        <div
          className={`${props.className} border-left-0 icon`}
          // style={{ width: "7%" }}
        >
          <img
            src={eye}
            style={{
              cursor: "pointer",
              margin: "0 auto",
              height: "24px",
              width: "24px",
            }}
            onClick={props.onEyeClick}
          />
        </div>
      </div>
    );
  } else if (props.inputFieldOption) {
    return (
      <div className="d-flex flex-row">
        <input
          type="text"
          className="input-field-icon"
          placeholder={props.placeholder || "Placeholder"}
        />
        <div className="icon">
          <img src={note} style={{ cursor: "pointer" }} />
        </div>
      </div>
    );
  } else if (props.fieldDropdown) {
    return (
      <div className="d-flex flex-row">
        <input
          type="text"
          className="input-field-icon"
          placeholder={props.placeholder || null}
        />
        <div className="icon">
          <CaretDown stroke="#343A40" style={{ cursor: "pointer" }} />
        </div>
      </div>
    );
  } else if (props.fieldDropdownFull) {
    return (
      <div
        style={{ position: "relative", ...props.style }}
        onClick={
          props.handleDropDownMenu
            ? () => props.handleDropDownMenu(props.id, props.rowId)
            : handleShow
        }
      >
        <div className="add-members-wrapper">
          <input
            type="text"
            className={`input-field-full ${
              props.typography ? props.typography : "s1"
            }`}
            placeholder={props.placeholder || null}
            style={{
              cursor: "pointer",
              background: props.inputBackground ?? "",
              width: "100%",
              ...props.inputStyle,
            }}
            // defaultValue={props?.value || ""}
            value={props?.value || ""}
            readOnly={props.readOnly}
            // defaultValue={props?.selected?.label || ""}
            disabled={props.disabled}
          />
          <div className="add-members-search-icon">
            <CaretDown stroke="#343A40" style={{ cursor: "pointer" }} />
          </div>
        </div>

        {(props.showMultipleDropDown
          ? props.showDropDown.menu &&
            props.showDropDown.click === props.id + props.rowId
          : show) && (
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              top: "47px",
              background: "#FFFFFF",
              boxShadow: "0px 6px 16px rgba(163, 163, 163, 0.12)",
              borderRadius: "10px",
              borderBottomRightRadius: "10px",
              width: "100%",
            }}
            className="d-flex flex-column invisible-scrollbar"
          >
            {props.options?.map((option, index) => {
              return (
                <span
                  className="input-field-teams-list s2 grey8"
                  key={index}
                  style={{
                    border: "none",
                    display: "flex",
                    paddingLeft: "15px",
                    textAlign: "left !important",
                  }}
                  onClick={(e) => props.onClick(option, props?.id)}
                >
                  {/* IF PROPS.COHORTS ? OPTION : OPTION.LABEL */}
                  {props.billings
                    ? option.label
                    : props.objectValueToRender
                      ? option[props?.objectValueToRender]
                      : option !== null && option}
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  } else if (props.fieldDropdownFullCohorts) {
    return (
      <div
        style={{ position: "relative", ...props.style }}
        onClick={
          props.handleDropDownMenu
            ? () => props.handleDropDownMenu(props.id, props.rowId)
            : handleShow
        }
      >
        <div className="add-members-cohort">
          <input
            type="text"
            className={`input-field-full ${
              props.typography ? props.typography : "s1"
            }`}
            placeholder={props.placeholder || null}
            style={{
              cursor: "pointer",
              background: props.inputBackground ?? "",
              width: "100%",
              ...props.inputStyle,
            }}
            // defaultValue={props?.value}
            value={props?.value}
            readOnly={props.readOnly}
            // defaultValue={props?.selected?.label}
            // disabled
          />
          {/* <img src={caret_down} style={{
						display: "flex", cursor: "pointer", background: props.inputBackground ?? "white",
						border: "none", borderRight: "1px solid #e9ecef", borderTop: "1px solid #e9ecef", borderBottom: "1px solid #e9ecef",
						borderRadius: "10px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0", paddingRight: "12px"
					}} /> */}
          <div className="add-members-cohort-icon">
            <CaretDown stroke="#343A40" style={{ cursor: "pointer" }} />
          </div>
        </div>

        {(props.showMultipleDropDown
          ? props.showDropDown.menu &&
            props.showDropDown.click === props.id + props.rowId
          : show) && (
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              top: "47px",
              background: "#FFFFFF",
              boxShadow: "0px 6px 16px rgba(163, 163, 163, 0.12)",
              borderRadius: "10px",
              borderBottomRightRadius: "10px",
              width: "100%",
            }}
            className="d-flex flex-column invisible-scrollbar"
          >
            {props.options?.map((option, index) => {
              return (
                <span
                  className="input-field-teams-list s2 grey8"
                  key={index}
                  style={{
                    border: "none",
                    display: "flex",
                    flex: 1,
                    paddingLeft: "15px",
                    textAlign: "left",
                  }}
                  onClick={(e) => props.onClick(option, props?.id)}
                >
                  {/* IF PROPS.COHORTS ? OPTION : OPTION.LABEL */}
                  {/* {props.conditionsDropDown ? option.condition : 
										props.valuesDropDown ? option.name : option} */}
                  {props.valuesDropDown
                    ? option.name
                    : props.conditionsDropDown
                      ? option.condition
                      : option}
                  {/* {option} */}
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  } else if (props.teamTypeDropdown) {
    return (
      <div
        style={{ position: "relative", ...props.style }}
        className="d-flex flex-row flex-wrap align-items-center"
        onClick={props.handleTeamTypeMenu}
      >
        <div className="add-members-wrapper">
          <input
            required={props.required}
            // autoComplete={props.autoComplete && "off" || ""}
            type="text"
            className={`${props.className} input-field-full s1`}
            name={props.name}
            value={props.value}
            defaultValue={props?.selected?.label}
            placeholder={props.placeholder || null}
            style={{ ...props.style }}
            readOnly={props.readOnly}
            // onChange={props.onChange}
            // onBlur={props.onBlur}
            disabled={props.disabled}
          />
          <div className="add-members-search-icon">
            <CaretDown stroke="#343A40" style={{ cursor: "pointer" }} />
          </div>
        </div>

        {props.show && (
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              top: "53px",
              background: "#FFFFFF",
              boxShadow: "0px 6px 16px rgba(163, 163, 163, 0.12)",
              borderRadius: "10px",
              borderBottomRightRadius: "10px",
              width: "100%",
            }}
            className="d-flex flex-column invisible-scrollbar"
          >
            {props.options?.map((option, index) => {
              return (
                <span
                  className="input-field-teams-list s2 grey8"
                  key={index}
                  style={{
                    border: "none",
                    display: "flex",
                    paddingLeft: "15px",
                    textAlign: "left !important",
                  }}
                  onClick={() => props.onClick(option)}
                >
                  {option}
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  } else if (props.fieldDropdownFullSearchIcon) {
    return (
      <div
        style={{ position: "relative", width: "114%", ...props.style }}
        className="d-flex flex-row flex-wrap align-items-center"
        onClick={handleShow}
      >
        <input
          type="text"
          className="input-field-icon-list s1"
          placeholder={props.placeholder || null}
          style={{ cursor: "pointer" }}
          defaultValue={props?.selected?.label}
          disabled
        />
        <div className="icon" style={{ width: "20px" }}>
          <Search
            stroke="#343A40"
            stroke-width="3"
            style={{ cursor: "pointer" }}
          />
        </div>

        {show && (
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              top: "45px",
              overflowY: "scroll",
              height: "180px",
            }}
            className="d-flex flex-column invisible-scrollbar"
          >
            {props.UsersData?.map((user) => (
              <span
                className="input-field-full-list"
                style={{ width: "650px", textAlign: "left", border: "none" }}
                key={user.id}
              >
                <Checkbox />
                {user.name} - {user.email}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  } else if (props.fieldDropdownSm) {
    return (
      <div className="d-flex flex-row">
        <input
          type="text"
          className="input-field-icon-sm"
          placeholder={props.placeholder || null}
        />
        <div className="icon">
          {/* <img
						src={caret_down}
						style={{ cursor: "pointer", backgroundColor: "white" }}
					/> */}
          <CaretDown stroke="#343A40" style={{ cursor: "pointer" }} />
        </div>
      </div>
    );
  } else if (props.inputFieldCheckbox) {
    return (
      <div className="d-flex input-field-checkbox">
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
        />
      </div>
    );
  } else if (props.inputFieldApplyCoupon) {
    return (
      <div
        className="d-flex align-items-center input-field-apply-coupon"
        style={{ ...props.style }}
      >
        <input
          required={props.required}
          type="text"
          className="input-field-apply-coupon-input"
          name={props.name}
          style={{ ...props.inputStyle }}
          value={props.value}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder || null}
          onChange={props.onChange}
          disabled={props.applied}
        />
        <button
          disabled={props.disabled}
          onClick={props.onClick}
          className="input-field-apply-coupon-button"
        >
          {props.applied ? "Remove" : "Apply"}
        </button>
        <span
          className="text-danger"
          style={{ position: "absolute", marginTop: "70px" }}
        >
          {props.errorText}
        </span>
      </div>
    );
  } else if (props.inputFieldFullExpand) {
    return (
      <div
        className="d-flex flex-row align-items-center justify-content-between"
        style={{ ...props.style }}
      >
        <input
          type="text"
          className="input-field-full-icon s2"
          placeholder={props.placeholder || null}
          style={{ ...props.inputStyle }}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
        />
        {/* <div style={{width: "20px", height: "20px", display: "flex", alignItems: "center",
					position:"absolute",right: props.detectedUserIcon ? "90px" : "15px"}} 
				onClick={props.toggleIcon}> */}
        <div
          style={{
            height: props.style.height,
            display: "flex",
            alignItems: "center",
            marginRight: "14px",
          }}
          onClick={props.toggleIcon}
        >
          <Search
            stroke="#343A40"
            stroke-width="3"
            style={{ width: "20px", height: "20px", marginRight: "14px" }}
          />
        </div>
      </div>
      // <div className='d-flex flex-row align-items-center'>
      // 	<input
      // 		type='text'
      // 		className='input-field-full-icon s2'
      // 		placeholder={props.placeholder || null}
      // 		style={{ ...props.style }}
      // 		value={props.value}
      // 		onChange={props.onChange}
      // 	/>
      // </div>
    );
  }
}

// ************************ OLD fieldDropdownFull ************************
// <div
// 	style={{ position: "relative", ...props.style }}
// 	className='d-flex flex-row flex-wrap align-items-center'
// 	onClick={handleShow}>
// 	<input
// 		type='text'
// 		className='input-field-icon-list s1'
// 		placeholder={props.placeholder || null}
// 		style={{ cursor: "pointer" }}
// 		defaultValue={props?.selected?.label}
// 		disabled
// 	/>
// 	<div className='icon' style={{ width: "15%" }}>
// 		<img src={caret_down} style={{ cursor: "pointer" }} />
// 	</div>

// 	{show && (
// 		<div
// 			style={{
// 				position: "absolute",
// 				zIndex: "1",
// 				top: "45px",
// 				overflowY: "scroll",
// 				height: "180px",
// 				borderBottomLeftRadius: "10px",
// 				borderBottomRightRadius: "10px",
// 			}}
// 			className='d-flex flex-column invisible-scrollbar'>
// 			{props.options?.map((option, index) => {
// 				return (
// 					<span
// 						className='input-field-full-list'
// 						key={index}
// 						onClick={() => props.onClick(option)}>
// 						{option.label}
// 					</span>
// 				);
// 			})}
// 		</div>
// 	)}
// </div>
