import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Field from "../../../../components/sub-components/Field";
import { getObjectivesDrawerTableData } from "../../../../redux/slices/ObjectivesSlice";
import { getUsersList } from "../../../../redux/slices/usersSlice";
import TreeViewSelect from "../../../../components/sub-components/TreeViewDropdown";
import { useTreeSearch } from "../../../../utilities/Hooks";

const ObjectiveForm = ({
  showConfirmationModal,
  isEditAllowed,
  objectiveForm,
  setObjectiveForm,
  getInputFieldsStyles,
  getInputFieldHeadingTextColor,
  objectiveType,
  addPeopleToObjectiveSelected,
  setAddPeopleToObjectiveSelected,
  objectivesDropdownData,
  objectivesChecked,
  currentProgressDisabledStates,
}) => {
  const dispatch = useDispatch();

  const { activeUsers } = useSelector((state) => state.usersReducer);

  const ownerNodes = activeUsers.map((user) => ({
    value: user.user_id,
    label: `${user.first_name} ${user.last_name}`,
  }));
  const {
    searchTerm: ownerSearchTerm,
    setSearchTerm: handleOwnerSearch,
    filteredNodes: filteredOwnerNodes,
  } = useTreeSearch(ownerNodes);

  const mainNodes = [
    {
      value: "organization",
      label: "Organization",
      children: [
        {
          value: "department",
          label: "Department",
          children: objectivesDropdownData?.objectives_departments?.map(
            (item) => ({
              value: "department" + item.id,
              label: item.name,
            }),
          ),
        },
        {
          value: "teams",
          label: "Teams",
          children: objectivesDropdownData?.objectives_teams?.map((item) => {
            return {
              value: "team" + item.id,
              label: item.name,
            };
          }),
        },
        {
          value: "users",
          label: "Users",
          children: objectivesDropdownData?.objectives_user_profile?.map(
            (user) => {
              return {
                value: "user" + user.user_id,
                label: user.first_name + " " + user.last_name,
              };
            },
          ),
        },
      ],
    },
  ];
  const {
    searchTerm,
    setSearchTerm: handleMainSearch,
    filteredNodes,
  } = useTreeSearch(mainNodes);

  const handleChangeObjectiveForm = (e) => {
    const { name, value } = e.target;
    setObjectiveForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name, date) => {
    setObjectiveForm((prevState) => ({
      ...prevState,
      [name]: dayjs(date).format("YYYY-MM-DD HH:mm:ss"),
    }));
  };

  const getQuarterForDates = (date) => {
    if (!date) {
      return "";
    }

    const month = dayjs(date).month() + 1;
    let quarter = "";

    if (month >= 1 && month <= 3) {
      quarter = "Q1";
    } else if (month >= 4 && month <= 6) {
      quarter = "Q2";
    } else if (month >= 7 && month <= 9) {
      quarter = "Q3";
    } else if (month >= 10 && month <= 12) {
      quarter = "Q4";
    }

    return quarter;
  };

  useEffect(() => {
    if (currentProgressDisabledStates) return;
    if (activeUsers.length === 0) {
      dispatch(getUsersList());
    }
  }, []);

  useEffect(() => {
    if (currentProgressDisabledStates) return;
    if (objectiveType && objectiveType?.name) {
      const fetchData = async () => {
        try {
          await dispatch(
            getObjectivesDrawerTableData(objectiveType?.name),
          ).unwrap();
        } catch (err) {
          console.log("error ", err);
        }
      };

      fetchData();
    }
  }, [objectiveType]);

  return (
    <>
      <div
        style={{
          borderTop: "1px solid #EBEBEB",
          margin: "0 -48px",
        }}
      />
      <div className="mt-4" style={{ flexWrap: "nowrap" }}>
        <div style={{ paddingBottom: "32px" }}>
          <div
            className={`body1-medium ${getInputFieldHeadingTextColor()}`}
            style={{ marginBottom: "12px" }}
          >
            Name
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <Field
              style={getInputFieldsStyles()}
              value={objectiveForm.name}
              name="name"
              onChange={handleChangeObjectiveForm}
              disabled={showConfirmationModal || currentProgressDisabledStates}
              inputFieldFull
              className="disable-default-focus"
            />
          </div>
        </div>
        <div
          className={`body1-medium ${getInputFieldHeadingTextColor()}`}
          style={{ marginBottom: "12px" }}
        >
          Description
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ paddingBottom: "32px" }}
        >
          <Field
            style={getInputFieldsStyles()}
            value={objectiveForm.description}
            name="description"
            onChange={handleChangeObjectiveForm}
            disabled={showConfirmationModal || currentProgressDisabledStates}
            inputFieldFull
          />
        </div>

        <div className="row p-0">
          <div className="col-lg-6" style={{ paddingBottom: "32px" }}>
            <div
              className={`body1-medium ${getInputFieldHeadingTextColor()}`}
              style={{ marginBottom: "12px" }}
            >
              Start Date
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name="start_date"
                value={
                  objectiveForm.start_date === null
                    ? null
                    : dayjs(objectiveForm.start_date)
                }
                maxDate={
                  objectiveForm.end_date ? dayjs(objectiveForm.end_date) : null
                }
                onChange={(date) => handleDateChange("start_date", date)}
                disabled={
                  showConfirmationModal ||
                  !isEditAllowed ||
                  currentProgressDisabledStates
                }
                slotProps={{ textField: { size: "small" } }}
                sx={{
                  "& .MuiInputBase-input": {
                    height: "28px",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#DEDEDE",
                    },
                    "&:hover fieldset": {
                      borderColor: "#b1b1b1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#b1b1b1",
                    },
                    "&MuiList-root": {
                      height: "40px",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DEDEDE",
                    "&:hover": {
                      borderColor: "yellow",
                    },
                  },
                  "&.Mui-focused": {
                    borderColor: "#b1b1b1",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
            <div className="body1 pl-2 mt-1">
              {getQuarterForDates(objectiveForm.start_date)}
            </div>
          </div>
          <div className="col-lg-6" style={{ paddingBottom: "32px" }}>
            <div
              className={`head7-medium ${getInputFieldHeadingTextColor()}`}
              style={{ marginBottom: "12px" }}
            >
              End Date
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name="end_date"
                value={
                  objectiveForm && objectiveForm.end_date === null
                    ? null
                    : dayjs(objectiveForm.end_date)
                }
                minDate={
                  objectiveForm.start_date
                    ? dayjs(objectiveForm.start_date)
                    : null
                }
                onChange={(date) => handleDateChange("end_date", date)}
                disabled={
                  showConfirmationModal ||
                  !isEditAllowed ||
                  currentProgressDisabledStates
                }
                slotProps={{ textField: { size: "small" } }}
                sx={{
                  "& .MuiInputBase-input": {
                    height: "28px",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DEDEDE",
                    "&:hover": {
                      borderColor: "#b1b1b1",
                    },
                    "&.Mui-focused": {
                      borderColor: "#b1b1b1",
                    },
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
            <div className="body1 pl-2 mt-1">
              {getQuarterForDates(objectiveForm.end_date)}
            </div>
          </div>

          <div className="col-lg-12" style={{ paddingBottom: "32px" }}>
            <span className={`body1-medium ${getInputFieldHeadingTextColor()}`}>
              Owner
            </span>
            <TreeViewSelect
              nodes={[
                {
                  value: "users",
                  label: "Users",
                  children: filteredOwnerNodes,
                },
              ]}
              appliedItems={objectiveForm.owner}
              setAppliedItems={(value) =>
                setObjectiveForm((prevState) => ({
                  ...prevState,
                  owner: value,
                }))
              }
              showSingleItem={true}
              objectivesChecked={[objectiveForm.owner[0]?.value]}
              disabled={currentProgressDisabledStates}
              searchTerm={ownerSearchTerm}
              handleSearch={handleOwnerSearch}
              // readOnly={false}
            />
          </div>
        </div>

        <div>
          <span className={`body1-medium ${getInputFieldHeadingTextColor()}`}>
            Who is this objective for?
          </span>
          <TreeViewSelect
            nodes={filteredNodes}
            disabled={currentProgressDisabledStates}
            appliedItems={addPeopleToObjectiveSelected}
            setAppliedItems={setAddPeopleToObjectiveSelected}
            objectivesChecked={objectivesChecked}
            searchTerm={searchTerm}
            handleSearch={handleMainSearch}
            readOnly={false}
          />
        </div>
      </div>
    </>
  );
};

export default ObjectiveForm;
