import React, { useState, useMemo, memo } from "react";
import BarGraph from "../../../components/Analytics/BarGraph";
import { generateGraphQuery } from "../../../utilities/CubeUtils";
import { GetGraphLoader, getGraphLoader } from "../../../utilities/Loaders";
import { useSelector } from "react-redux";
import { getGraphGranularityMappings } from "../../../utilities/Utils";
import { secure_instance } from "../../../axios/axios-config";
import { getEmojisMapping } from "../../../utilities/GraphUtils";

function SurveyAnalyticsOverview(props) {
  const {
    graphName,
    graphType,
    cardContainerHeight,
    graphContainerHeight,
    handleSurveyClick,
  } = props;

  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );

  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const [graphData, setGraphData] = useState([]);

  // useMemo(async () => {
  //   if (isTriggerProductivityGraphRefetch || isTriggerRefetch) {
  //     const query = generateGraphQuery(
  //       dashboardGlobalFilters,
  //       graphUserFilters,
  //       selectedGroupByItem,
  //       getGraphGranularityMappings(selectedGranularity),
  //     );

  //     // handleGraphDataFetch(query, selectedGroupByItem, selectedGranularity);
  //   }
  // }, [isTriggerProductivityGraphRefetch, isTriggerRefetch]);

  const getGraphAPIData = async (query) => {
    try {
      const request = await secure_instance.request({
        url: `v1/survey_response/categorygraph/${graphType}`,
        method: "GET",
      });
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  useMemo(async () => {
    const data = await getGraphAPIData();
    setGraphData(data?.length > 0 ? data : []);
    console.log("data => ", data);
  }, []);

  return (
    <div className="w-100" style={{ padding: "32px 0" }}>
      <div
        style={{
          height: cardContainerHeight ? cardContainerHeight : "312px",
          border: "1px solid #C6C6C6",
          borderRadius: "20px",
          background: "#FFFFFF",
          boxShadow: "2px 2px 15px 0px #E5D5F2",
          // paddingBottom: "8px",
          cursor: "pointer",
        }}
        onClick={() => handleSurveyClick(graphName)}
      >
        <div
          style={{
            padding: "24px 32px 0 32px",
            marginBottom: graphData.length === 0 && "80px",
          }}
          className="s1 d-flex align-items-center"
        >
          {graphName}
        </div>
        {isGraphLoading ? (
          <div
            style={{
              minWidth: "95%",
              paddingLeft: "30px",
              height: "312px",
              marginTop: "34px",
              maxHeight: "fit-content",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{
                position: "relative",
                margin: "0 auto",
                width: "100%",
                height: "100%",
              }}
            >
              {/* {getGraphLoader()} */}
              <GetGraphLoader />
              <div style={{ position: "absolute", left: "43%", top: "25%" }}>
                <div className="h5 grey9 mt-3">Loading Data</div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              minWidth: "95%",
              maxWidth: "99%",
              paddingLeft: "30px",
              marginTop: "10px",
            }}
          >
            {/* <BarGraph
              groupByGraphKeys={["hot dog"]}
              graphData={dummyBarData}
              currentFilters={[]}
              graphName={graphName}
              indexBy={"country"}
              hideDateOnToolTip={true}
              tooltip={null}
              padding={0.75}
              colors={"#9300FF"}
              isAxisBottomDisplay={true}
              isAxisLeftDisplay={true}
              enableGridX={false}
              enableGridY={false}
              // borderRadius={5}
              margin={{ top: 30, right: 0, bottom: 110, left: 30 }}
              graphContainerHeight={
                graphContainerHeight ? graphContainerHeight : "320px"
              }
              // maxValue={300}
              // graphContainerHeight={"106px"}
            /> */}
            <BarGraph
              // groupByGraphKeys={["count"]}
              graphData={graphData}
              // selectedGranularity={"monthly"}
              // currentFilters={[]}
              graphName={graphName}
              indexBy={"response"}
              hideDateOnToolTip={true}
              tooltip={null}
              padding={0.9}
              colors={"#9300FF"}
              // isAxisBottomDisplay={true}
              // isAxisLeftDisplay={true}
              // enableGridX={false}
              // enableGridY={true}
              borderRadius={3.4}
              // margin={{ top: 0, right: 0, bottom: 30, left: 30 }}
              margin={{ top: 5, right: 10, bottom: 110, left: 24 }}
              graphContainerHeight={"320px"}
              isAxisBottomDisplay={{
                tickSize: 25,
                tickPadding: 5,
                tickRotation: 0,
                // legend: "DumpTableMain.eventTime",
                legendPosition: "middle",
                legendOffset: 65,
                // ------------------- THIS FUNCTION CUSOTMIZES FONT, BUT DOESNT WORK WITH CANVAS VERSION  -------------------
                // tickTextStyle: {
                //   fontSize: 24, // Increase this value to make the font larger
                // },
                renderTick: ({ x, y, textAnchor, textX, textY, value }) => (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY})`}
                      style={{ fontSize: "22px" }}
                    >
                      {getEmojisMapping(value)}
                    </text>
                  </g>
                ),
                // format: (value) => {
                //   return getEmojisMapping(value);
                // },
              }}
              mapEmojis
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(SurveyAnalyticsOverview);
