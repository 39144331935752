/* eslint-disable indent */

// generateCubeJsQuery Not used currently
export const generateCubeJsQuery = (
  graphType,
  selectedGranularity,
  filters,
  groupBy,
) => {
  let measures, dimensions, timeDimensions, order, limit;

  switch (graphType) {
    case "productivityGraph":
      measures = ["DumpTableMain.count"];
      dimensions = null;
      timeDimensions = [
        {
          dimension: "DumpTableMain.eventTime",
          granularity: selectedGranularity.toLowerCase(),
          dateRange: "This year",
        },
      ];
      filters = [...filters];
      order = null;
      limit = null;
      break;

    case "top10PerformersGraph":
      measures = ["DumpTableMain.count"];
      dimensions = ["DumpTableMain.userId"];
      timeDimensions = null;
      filters = [...filters];
      order = [["DumpTableMain.count", "desc"]];
      limit = 10;
      break;
    case "eventTypeGraph":
      measures = ["DumpTableMain.count"];
      dimensions = ["DumpTableMain.userId"];
      timeDimensions = [
        {
          dimension: "DumpTableMain.eventTime",
        },
      ];
      filters = [...filters];
      order = {
        "DumpTableMain.count": "desc",
      };
      limit = 5000;

      break;
    default:
      // Default values or error handling
      break;
  }

  if (groupBy !== "none") {
    if (dimensions === null) {
      dimensions = [];
    }
    if (groupBy === "users") {
      dimensions.push("DumpTableMain.userId");
    } else if (groupBy === "event types") {
      dimensions.push("DumpTableMain.eventType");
    } else if (groupBy === "source") {
      dimensions.push("DumpTableMain.source");
    }
  }

  const query = {
    measures,
    dimensions,
    timeDimensions,
    filters,
    order,
    limit,
  };

  return query;
};

export const generateGraphQuery = (
  filters,
  userfilters,
  groupBy,
  selectedGranularity,
) => {
  let measure, tablemain, group, order_by;

  measure = "_id";
  tablemain = "main";
  // group = ["event_type", "user_id"];
  group = [selectedGranularity];
  userfilters;
  filters;
  if (groupBy !== "none") {
    if (groupBy === "users") {
      group.push("user_id");
    } else if (groupBy === "event types") {
      group.push("event_type");
    } else if (groupBy === "sources") {
      group.push("source");
    }
    // else if (groupBy === "teams") {
    //   group.push("group_id");
    // }
  }

  // const updatedGraphUserFilters =
  //   groupBy === "teams"
  //     ? { ...userfilters, group: ["group_id"] }
  //     : { ...userfilters };

  order_by = {
    col: selectedGranularity,
    order: "asc",
  };

  const query = {
    order_by,
    measure,
    tablemain,
    group,
    // userfilters: updatedGraphUserFilters,
    userfilters,
    filters,
  };

  return query;
};

export const generateQueryForLineGraph = (
  filters,
  userfilters,
  // _,
  selectedGranularity,
  groupByOption,
) => {
  let x, y, tablemain, group, key, order_by, fill, fetch, groupBy;

  switch (groupByOption) {
    case "users":
      groupBy = "user_id";
      break;

    case "teams":
      groupBy = "user_id";
      break;

    // case "cohorts":
    //   group.push("cohort");
    //   break;
  }

  // const modifyUserFiltersForLineGraph = JSON.parse(JSON.stringify(userfilters));
  // modifyUserFiltersForLineGraph.group = ["group_id"];
  // x = "date";
  x = selectedGranularity;
  y = "_id";
  tablemain = "main";
  // group = [];
  group = [];
  // order_by = {
  //   col: "count",
  //   order: "asc",
  // };
  order_by = {
    col: selectedGranularity,
    order: "asc",
  };
  // fill = {
  //   col: "date",
  //   step: "date",
  // };
  key = "";
  // fill = {
  //   col: selectedGranularity,
  //   step: selectedGranularity,
  // };
  // key = groupBy;
  // key = "";

  fetch = {
    model: "Group",
    filters: [
      //  {
      //     "col":"email",
      //     "operator":"==",
      //     "value":"frontend@venturenox.com"
      // }
    ],
  };

  // if (filters[1]?.col === "event_name") {
  //   filters.splice(1, 1);
  // }

  const query = {
    x,
    y,
    tablemain,
    group,
    key,
    order_by,
    fill,
    userfilters: {
      model: "GroupMembers",
      filters: [
        //  {
        //     "col":"group_id",
        //     "operator":"==",
        //     "value":"23"
        // }
      ],
      group: ["group_id"],
    },
    fetch,
    filters,
  };

  return query;
};

export const generateQueryForSynchrounousCommunicationGraph = (
  filters,
  userfilters,
  selectedGranularity,
  groupByOption,
) => {
  let x, y, tablemain, group, key, order_by, fill, groupBy;
  const communicationFilters = [...filters];
  // x = "date";
  switch (groupByOption) {
    case "users":
      groupBy = "user_id";
      break;

    // case "teams":
    //   groupBy = "user_id";
    //   break;

    // case "cohorts":
    //   group.push("cohort");
    //   break;
  }
  x = selectedGranularity;
  y = "_id";
  tablemain = "main";
  group = ["event_name"];

  // const modifyUserFiltersForLineGraph = JSON.parse(JSON.stringify(userfilters));
  // modifyUserFiltersForLineGraph.group = ["group_id"];
  // order_by = {
  //   col: "count",
  //   order: "asc",
  // };
  order_by = {
    col: selectedGranularity,
    order: "desc",
  };
  // order_by = {
  //   col: "date",
  //   order: "asc",
  // };
  fill = {
    col: selectedGranularity,
    step: selectedGranularity,
  };
  key = groupBy;
  communicationFilters.push({
    col: "event_name",
    operator: "in",
    value: [
      "communication_new_message",
      "communcation_reaction",
      "communication_call_misc",
    ],
  });

  // fill = {
  //   col: "date",
  //   step: "date",
  // };
  // key = "event_type";

  const query = {
    x,
    y,
    tablemain,
    group,
    key,
    order_by,
    fill,
    // userfilters: modifyUserFiltersForLineGraph, // commented after backend update
    filters: communicationFilters,
  };

  return query;
};
