import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import { DateRangePicker } from "../../../components/DatePickers/DateRangePicker";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import Button from "../../../components/sub-components/Button";
import Field from "../../../components/sub-components/Field";
import BoardSectionList from "./components/BoardSectionList";
import { getTeamsData } from "../../../redux/slices/teamsSlice";
import {
  getObjectiveData,
  toggleSideMenu,
} from "../../../redux/slices/ObjectivesSlice";
import ObjectivesSideMenu from "./ObjectivesMenu";

const Objectives = () => {
  const dispatch = useDispatch();

  const [organizationItemSelected, setOrganizationItemSelected] = useState({});
  const [organizationItemApplied, setOrganizationItemApplied] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [filterInputValue, setFilterInputValue] = useState("");
  const [userSelected, setUserSelected] = useState({});
  const [userApplied, setUserApplied] = useState({});
  const [isCurrentViewMenuOpen, setIsCurrentViewMenuOpen] = useState(false);
  const [isSortByOptionMenuOpen, setIsSortByOptionMenuOpen] = useState("none");
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [currentrow, setCurrentrow] = useState({});
  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    useState(false);

  const currentViewAnchorEl = useRef(null);
  const nestedFilterAnchorEl = useRef(null);

  const { objectivesData } = useSelector((state) => state.objectivesReducer);
  console.log("objectivesDataobjectivesData", objectivesData);
  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => {
      console.log("prevOpen:", prevOpen);
      return !prevOpen;
    });
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };
  const handleApplyUserType = (item) => {
    console.log("userSelected inside handleApplyUserType", item);
    setUserApplied(item);

    handleNestedFilterationMenuClose();
  };

  // const handleClickCheckbox = (item) => {
  //   console.log("item > handleClickCheckbox", item);
  //   setUserSelected(item);
  //   handleApplyUserType(item);
  // };

  const handleCancelUserType = () => {
    setUserSelected(userApplied);
    handleNestedFilterationMenuClose();
  };

  const handleClickCurrentViewOption = (item) => {
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const handleApplyOrganizationFilter = () => {
    setOrganizationItemApplied(organizationItemSelected);
    handleNestedFilterationMenuClose();
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  const handleCreateNewObjective = () => {
    dispatch(toggleSideMenu());
  };

  const sortData = (criteria) => {
    console.log("criteriacriteria", criteria);
    const filteredData = filterObjectivesByDate(
      objectivesData, // Your array of objectives
      startDate, // Filter start date
      endDate, // Filter end date
    );
    switch (criteria.name) {
      // case "Date":
      //   console.log("Date");
      //   return [...objectivesData].sort(
      //     (a, b) => new Date(a.start_date) - new Date(b.start_date),
      //   );
      case "Date":
        console.log("end_date");
        return [...filteredData].sort(
          (a, b) => new Date(a.end_date) - new Date(b.end_date),
        );
      case "Progress":
        console.log("Progress");
        return [...filteredData].sort(
          (a, b) => b.overall_current_progress - a.overall_current_progress,
        );
      default:
        console.log("default");
        return filteredData; // Default unsorted
    }
  };

  const filterObjectivesByDate = (data, filterStartDate, filterEndDate) => {
    // Convert filter dates to Date objects and remove the time component
    const filterStart = new Date(filterStartDate);
    filterStart.setHours(0, 0, 0, 0); // Set time to 00:00:00
    const filterEnd = new Date(filterEndDate);
    filterEnd.setHours(23, 59, 59, 999); // Include the full end date

    return data.filter((objective) => {
      const startDate = new Date(objective.start_date);
      startDate.setHours(0, 0, 0, 0); // Remove time from start_date
      const endDate = new Date(objective.end_date);
      endDate.setHours(0, 0, 0, 0); // Remove time from end_date

      // Check if the objective's date range overlaps with the filter range
      return (
        startDate <= filterEnd && endDate >= filterStart // Overlapping any part of the range
      );
    });
  };

  useEffect(() => {
    dispatch(getTeamsData());
    dispatch(getObjectiveData());
  }, []);

  console.log("userSelected???", userSelected);
  return (
    <div
      className="page-body-new"
      style={{ display: "block", padding: "0 34px 44px 44px" }}
    >
      <div className="row p-0">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <PopperDropdown
              anchorRef={currentViewAnchorEl}
              isDropdownOpen={isCurrentViewMenuOpen}
              handleToggle={() =>
                handleGenericMenuOpen(setIsCurrentViewMenuOpen)
              }
              handleClose={() =>
                handleGenericMenuClose(setIsCurrentViewMenuOpen)
              }
              dropdownDisplayText="Users"
              customRenderFieldChildren={
                <div
                  className="d-flex align-items-center head4-semi-bold"
                  style={{ cursor: "pointer" }}
                >
                  Organization{" "}
                  <CaretDown stroke="black" stroke-width="3" className="ml-2" />
                </div>
              }
              dropdownWithSearchAndCheckbox
              dropdownData={organizationData}
              handleClickCheckbox={handleClickCurrentViewOption}
              handleApply={handleApplyOrganizationFilter}
              handleCancel={handleCancelOrganizationFilter}
              dropdownItemsSelected={organizationItemSelected}
              keyToAccess="name"
            />

            <Button
              buttonAddtoTeam
              title="Objective"
              onClick={() => handleCreateNewObjective()}
              style={{
                boxShadow: "none",
                background: "#9300FF",
                padding: "0 36px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: "32px" }}
      >
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 0" }}
        >
          {" "}
          <PopperDropdown
            anchorRef={nestedFilterAnchorEl}
            isDropdownOpen={isNestedFilterationMenuOpen}
            handleToggle={() =>
              handleGenericMenuOpen(setIsNestedFilterationMenuOpen)
            }
            handleClose={() =>
              handleGenericMenuClose(setIsNestedFilterationMenuOpen)
            }
            dropdownDisplayText="Users"
            customRenderFieldChildren={
              <div
                className="d-flex align-items-center body1"
                style={{
                  cursor: "pointer",
                  border: "1px solid #9300FF",
                  padding: "10px 12px 10px 12px",
                  borderRadius: "10px",
                  marginRight: "16px",
                }}
              >
                {Object.keys(userApplied).length === 0
                  ? "Sort By"
                  : userApplied.name}
                {/* {userSelected === null ? "Sort By" : userSelected} */}
                <CaretDown stroke="black" stroke-width="2" className="ml-2" />
              </div>
            }
            dropdownWithSearchAndCheckbox
            dropdownData={[
              { id: 1, name: "Date" },
              { id: 2, name: "Progress" },
            ]}
            // handleClickCheckbox={handleClickCheckbox}
            handleApply={handleApplyUserType}
            handleCancel={handleCancelUserType}
            dropdownItemsSelected={userApplied}
            searchTerm={filterInputValue}
            // searchBy="name"
            keyToAccess={"name"}
          />
          <Field
            inputFieldFullSearchIcon
            placeholder={`Search`}
            value={filterInputValue}
            onChange={(e) => setFilterInputValue(e.target.value)}
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "52px",
              borderRight: "1px solid #DEDEDE",
              borderRadius: "10px",
            }}
          />
        </div>

        <div
          className="d-flex align-items-center"
          style={{
            height: "36px",
            background: "#F8F9FA",
            border: "1px solid #E9ECEF",
            borderRadius: "10px",
            padding: "6px",
            cursor: "pointer",
          }}
        >
          <DateRangePicker
            value={{ from: startDate, to: endDate }}
            fetchDatesData={fetchDatesData}
            setIsDateMenuOpen={setIsDateMenuOpen}
            isDateMenuOpen={isDateMenuOpen}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>

      <ObjectivesSideMenu
        currentrow={currentrow}
        setCurrentrow={setCurrentrow}
      />

      <div className="row p-0">
        <div className="col-12">
          <BoardSectionList
            filterInputValue={filterInputValue}
            objectivesData={sortData(userApplied)}
          />
        </div>
      </div>
    </div>
  );
};

export default Objectives;
